import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import cn from 'classnames'
import './index.css'
import { PaymentDialogHeaderProps } from './types'

const PaymentDialogHeader = ({
  steps,
  currentStep,
  name,
  handleOnClose,
  isPaymentIntentCreating,
}: PaymentDialogHeaderProps) => {
  return (
    <>
      {/* PAGE INDICATOR ROW */}
      <div className={cn('row', 'pageIndicatorRow')}>
        {steps.map((step) => {
          return (
            <div
              key={step}
              className={cn('stepIndicator', currentStep === step && 'activeStepIndicator')}
            />
          )
        })}
      </div>

      {/* SUPPORT PLAYER ROW */}
      <div className={cn('row', 'supportPlayerRow')}>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <Typography
            style={{
              fontSize: '30px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: '800px',
            }}
          >
            <strong>{currentStep}</strong> - {name}
          </Typography>
        </div>
        <Button
          disabled={isPaymentIntentCreating}
          variant="contained"
          sx={{
            padding: '0px',
            height: '45px',
            width: '45px',
            minWidth: '45px',
            borderRadius: '10px',
            backgroundColor: '#3C484C',
            color: '#F2F2F3',
          }}
          size="small"
          onClick={handleOnClose}
        >
          <CloseIcon />
        </Button>
      </div>
    </>
  )
}

export default PaymentDialogHeader
