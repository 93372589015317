import { Typography } from '@mui/material'
import { CloseButton } from 'components/CloseButton'
import styles from './DialogHeader.module.scss'
import { DialogHeaderProps } from './types'

const DialogHeader = ({ title, handleOnClose }: DialogHeaderProps) => {
  return (
    <>
      {/* SUPPORT PLAYER ROW */}
      <div className={styles.row}>
        <div className={styles.header}>
          <Typography className={styles.header_text}>
            <strong>{title}</strong>
          </Typography>
        </div>
        <CloseButton handleOnClose={handleOnClose} />
      </div>
    </>
  )
}

export default DialogHeader
