import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { PaymentIntent } from 'types'
import { log } from 'util/firebase'

export default function CheckoutForm({
  paymentIntent,
  isPaymentElementReady,
  setIsPaymentElementReady,
  handleCloseModalFromButton,
}: {
  paymentIntent: PaymentIntent
  isPaymentElementReady: boolean
  setIsPaymentElementReady: React.Dispatch<React.SetStateAction<boolean>>
  handleCloseModalFromButton: () => void
}) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [ammount, setAmmount] = useState<number>()
  const [isPaymentSuccessfull, setIsPaymentSuccessfull] = useState<boolean>(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!paymentIntent) {
      return
    }

    stripe.retrievePaymentIntent(paymentIntent?.paymentIntent).then(({ paymentIntent }) => {
      setAmmount(paymentIntent?.amount! / 100)

      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Please enter your card details')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [paymentIntent, stripe])

  const handleSubmit = async (event: React.FormEvent<EventTarget>) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_WEB_URL}`,
      },
      redirect: 'if_required',
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      setMessage(error.message || 'Undefined error')
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      setMessage(
        'Payment succeeded!&&You will receive and e-mail shortly with all the necessary information.&&This dialog will automatically close in 5 seconds.',
      )
      log({
        eventName: 'purchase',
        eventData: {
          test: 'test',
        },
      })
      setTimeout(() => {
        handleCloseModalFromButton()
      }, 5000)
      setIsPaymentSuccessfull(true)
    }

    setIsLoading(false)
  }

  return (
    <div
      style={{
        minHeight: '350px',
      }}
    >
      <div style={{ marginTop: '64px' }}>
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement
            id="payment-element"
            onReady={() => {
              setIsPaymentElementReady(true)
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginTop: '32px',
              marginBottom: '32px',
              justifyContent: 'center',
            }}
          >
            {message?.split('&&').map((paragraph, index) => (
              <Typography
                key={index}
                variant="body1"
                style={{ color: '#fff', fontWeight: 500, textAlign: 'center' }}
              >
                {paragraph}
              </Typography>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              marginTop: '16px',
              justifyContent: 'center',
            }}
          >
            <Button
              disabled={isLoading || !isPaymentElementReady || !ammount || isPaymentSuccessfull}
              type="submit"
              variant="contained"
              disableRipple={true}
              size="large"
              sx={{
                padding: '16px',
                borderRadius: '10px',
                backgroundColor: '#3C484C',
                boxShadow: 'none',
                color: '#F2F2F3',
                width: '300px',
              }}
            >
              <Typography>
                {isLoading || !isPaymentElementReady || !ammount ? 'Loading...' : `Pay €${ammount}`}
              </Typography>
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
