interface BadRequestError extends Error {
  body?: Record<string, unknown>
  responseStatus: number
}

// eslint-disable-next-line no-redeclare
class BadRequestError extends Error {
  constructor(responseStatus: number, errorBody: Record<string, unknown>) {
    super('Could not process the request!')

    this.name = 'BadRequestError'
    this.body = errorBody
    this.responseStatus = responseStatus

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BadRequestError)
    }
  }
}

export default BadRequestError
