import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AppContext from 'contexts/App'
import { useContext } from 'react'
import { SuccessSectionProps } from './types'

const SuccessSection = ({ section }: SuccessSectionProps) => {
  const { user } = useContext(AppContext)

  return (
    <Box
      style={{
        width: '100%',
        borderRadius: 10,
      }}
    >
      {section === 'success_login' && (
        <Box>
          <Typography variant="h6" mb={2} fontWeight={700}>
            Welcome {user?.firstName ? user?.firstName : user?.email}
          </Typography>
          <Typography variant="body1" mb={3}>
            Thank you for logging into your account.
          </Typography>
        </Box>
      )}
      {section === 'success_signup' && (
        <Box>
          <Typography variant="h5" mb={2}>
            Welcome {user?.firstName ? user?.firstName : user?.email}.
          </Typography>
          <Typography variant="body1" mb={3}>
            Your account has been created successfully, and you are now logged in.
          </Typography>
        </Box>
      )}
      {section === 'success_forgot_password' && (
        <Box>
          <Typography variant="h5" mb={2}>
            Welcome {user?.firstName ? user?.firstName : user?.email}.
          </Typography>
          <Typography variant="body1" mb={3}>
            Your password has been updated successfully, and you are now logged in.
          </Typography>
        </Box>
      )}

      <Typography mt={3}>You can now proceed to the next step.</Typography>
    </Box>
  )
}

export default SuccessSection
