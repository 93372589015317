import React from 'react'
import { NewsItem, NotificationParams, Player, Reward, UserType } from 'types'
import { ConsentType } from 'util/consent'

type AppContextType = {
  appIsInitialized?: boolean
  isAuthenticated?: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  authToken?: string
  setAuthToken: React.Dispatch<React.SetStateAction<string>>
  user?: UserType
  setUser: React.Dispatch<React.SetStateAction<UserType | undefined>>
  setNotification: React.Dispatch<React.SetStateAction<NotificationParams>>
  stripeSecret: string
  setStripeSecret: React.Dispatch<React.SetStateAction<string>>
  players: Player[]
  setPlayers: React.Dispatch<React.SetStateAction<Player[]>>
  countries: Record<string, string> | undefined
  setCountries: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>
  motd: Record<string, string> | undefined
  setMotd: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>
  analytics: any
  consent: ConsentType
  setConsent: React.Dispatch<React.SetStateAction<ConsentType>>
  isAuthenticationDialogOpened: boolean
  setIsAuthenticationDialogOpened: React.Dispatch<React.SetStateAction<boolean>>
  news: NewsItem[]
  setNews: React.Dispatch<React.SetStateAction<NewsItem[]>>
  rewards: Reward[]
  setRewards: React.Dispatch<React.SetStateAction<Reward[]>>
}

const AppContext = React.createContext<AppContextType>({
  setIsAuthenticated: () => {},
  setAuthToken: () => {},
  setUser: () => {},
  setNotification: () => {},
  stripeSecret: '',
  setStripeSecret: () => {},
  players: [],
  setPlayers: () => {},
  countries: {},
  setCountries: () => {},
  motd: {},
  setMotd: () => {},
  analytics: null,
  consent: null,
  setConsent: () => {},
  isAuthenticationDialogOpened: false,
  setIsAuthenticationDialogOpened: () => {},
  news: [],
  setNews: () => {},
  rewards: [],
  setRewards: () => {},
})
export default AppContext
