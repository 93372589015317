import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import Typography from '@mui/material/Typography'
import { Component, ErrorInfo, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './ErrorBoundary.module.scss'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

const FallbackUI = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
      navigate(0)
    }, 3000)
  }, [navigate])

  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>
        <div className={styles.icon}>
          <ReportProblemIcon className={styles.icon_size} />
        </div>
        <div>
          <Typography variant="h3">Something went wrong.</Typography>
          <Typography variant="body1">
            The Nordensa team has been notified. You will be redirected to the home page in 3
            seconds.
          </Typography>
        </div>
      </div>
    </div>
  )
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return <FallbackUI />
    }

    return this.props.children
  }
}

export default ErrorBoundary
