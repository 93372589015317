interface VagueAPIError extends Error {
  body?: Record<string, unknown>
  responseStatus: number
}

// eslint-disable-next-line no-redeclare
class VagueAPIError extends Error {
  constructor(responseStatus: number, errorBody: Record<string, unknown>) {
    super(`Vague API Error! Unhandled status code ${responseStatus}!`)

    this.name = 'VagueAPIError'
    this.body = errorBody
    this.responseStatus = responseStatus

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, VagueAPIError)
    }
  }
}

export default VagueAPIError
