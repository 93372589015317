import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { AuthenticationSection } from 'components/AuthenticationDialog'
import LoadingOverlay from 'components/LoadingOverlay'
import { PaymentDialogAuthenticationStep } from 'components/PaymentDialogAuthenticationStep'
import PaymentDialogCompleteInformationStep from 'components/PaymentDialogCompleteInformationStep'
import PaymentDialogConfirmPurchaseStep from 'components/PaymentDialogConfirmPurchaseStep'
import PaymentDialogHeader from 'components/PaymentDialogHeader'
import PaymentDialogPaymentDetailsStep from 'components/PaymentDialogPaymentDetailsStep'
import PaymentDialogSupportStep from 'components/PaymentDialogSupportStep'
import AppContext from 'contexts/App'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import createIntent from 'services/Payment/createIntent'
import { PaymentIntent } from 'types'
import { PaymentDialogProps } from './types'

const PaymentDialog = ({
  isFundingModalOpen,
  setIsFundingModalOpen,
  player,
}: PaymentDialogProps) => {
  const { user, authToken, isAuthenticated } = useContext(AppContext)
  const [selectedShares, setSelectedShares] = useState<number>(1)
  const [currentStep, setCurrentStep] = useState<string>('Support')
  const [isContractAccepted, setIsContractAccepted] = useState<boolean>(false)
  const [selectedSharesTotalPrice, setSelectedSharesTotalPrice] = useState<number>(0)
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null)
  const [isPaymentIntentCreating, setIsPaymentIntentCreating] = useState<boolean>(false)
  const [isPaymentElementReady, setIsPaymentElementReady] = useState<boolean>(false)
  const [hasPaymentIntentError, setHasPaymentIntentError] = useState<boolean>(false)
  const [section, setSection] = useState<AuthenticationSection>('login')
  const [steps, setSteps] = useState<string[]>([])

  useEffect(() => {
    if (!isAuthenticated) {
      setSteps([
        'Support',
        'Authentication',
        'Complete Information',
        'Confirm Purchase',
        'Payment Details',
      ])
    } else if (!user?.profileCompleted) {
      setSteps(['Support', 'Complete Information', 'Confirm Purchase', 'Payment Details'])
    } else {
      setSteps(['Support', 'Confirm Purchase', 'Payment Details'])
    }
  }, [isAuthenticated, user?.profileCompleted])

  const {
    firstName,
    lastName,
    rewards,
    budgetSplit,
    availability: { endDate },
    shares: { availableShares, totalShares, price, processingFee },
    imageUrl,
    playerId,
  } = player
  const availabilityEndDate = dayjs(endDate)
  const now = dayjs()
  const remaininginCampaignDays = availabilityEndDate.diff(now, 'days')
  const remaininginCampaignHours = availabilityEndDate.diff(now, 'hours')
  const remaininginCampaignTime =
    remaininginCampaignDays === 0
      ? `${remaininginCampaignHours} hours left`
      : `${remaininginCampaignDays} days left`
  const detailsText = rewards ? rewards.split('\n\n') : ['', '']
  const chanceText = detailsText[0].split('CHANCE')[1]
  const rewardsText = detailsText[1].split('REWARDS')[1]
  const fundedShares = totalShares - availableShares
  const fundedPercent = (fundedShares * 100) / totalShares

  const handleCloseModalFromButton = () => {
    setIsFundingModalOpen(false)
    setTimeout(() => {
      setSelectedShares(1)
      setCurrentStep('Support')
      setPaymentIntent(null)
      setIsPaymentIntentCreating(false)
      setIsContractAccepted(false)
      setHasPaymentIntentError(false)
      setSection('login')
    }, 300)
  }

  const handleOnClose = (event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
    if (reason && reason === 'backdropClick') return
    handleCloseModalFromButton()
  }

  const handleCreatePaymentIntent = async () => {
    setHasPaymentIntentError(false)
    if (isContractAccepted) {
      try {
        setIsPaymentIntentCreating(true)

        const res = await createIntent({
          authToken: authToken!,
          playerId: playerId,
          shares: selectedShares,
          fingerprint: 'test123',
        })

        setPaymentIntent(res)
        setCurrentStep('Payment Details')
      } catch (error) {
        // TODO: handle error
        // console.log(error)
        setHasPaymentIntentError(true)
        setIsContractAccepted(false)
      }
      setIsPaymentIntentCreating(false)
    }
  }

  useEffect(() => {
    setSelectedSharesTotalPrice(selectedShares * (price / 100))
  }, [price, selectedShares])

  return (
    <Dialog
      open={isFundingModalOpen}
      onClose={handleOnClose}
      maxWidth="lg"
      disableEscapeKeyDown={true}
      PaperComponent={({ children }) => {
        return (
          <div
            style={{
              width: '1050px',
              borderRadius: '32px',
            }}
          >
            {children}
          </div>
        )
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: '#FC6D4B',
          borderRadius: '32px',
          color: '#24251E',
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 1,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {isPaymentIntentCreating && <LoadingOverlay />}
        <PaymentDialogHeader
          steps={steps}
          currentStep={currentStep}
          handleOnClose={handleCloseModalFromButton}
          name={`${firstName} ${lastName}`}
          isPaymentIntentCreating={isPaymentIntentCreating}
        />

        {currentStep === 'Support' && (
          <PaymentDialogSupportStep
            availableShares={availableShares}
            fundedPercent={fundedPercent}
            remaininginCampaignTime={remaininginCampaignTime}
            chanceText={chanceText}
            rewardsText={rewardsText}
            budgetSplit={budgetSplit}
            selectedShares={selectedShares}
            setSelectedShares={setSelectedShares}
            selectedSharesTotalPrice={selectedSharesTotalPrice}
            profileCompleted={user?.profileCompleted ? true : false}
            setCurrentStep={setCurrentStep}
            isAuthenticated={isAuthenticated || false}
          />
        )}
        {currentStep === 'Authentication' && (
          <PaymentDialogAuthenticationStep
            setCurrentStep={setCurrentStep}
            section={section}
            setSection={setSection}
          />
        )}

        {currentStep === 'Complete Information' && (
          <PaymentDialogCompleteInformationStep setCurrentStep={setCurrentStep} />
        )}

        {currentStep === 'Confirm Purchase' && (
          <PaymentDialogConfirmPurchaseStep
            selectedShares={selectedShares}
            pricePerShare={price / 100}
            processingFee={processingFee / 100}
            imageUrl={imageUrl}
            isContractAccepted={isContractAccepted}
            setIsContractAccepted={setIsContractAccepted}
            handleCreatePaymentIntent={handleCreatePaymentIntent}
            isPaymentIntentCreating={isPaymentIntentCreating}
            hasPaymentIntentError={hasPaymentIntentError}
          />
        )}

        {currentStep === 'Payment Details' && (
          <PaymentDialogPaymentDetailsStep
            paymentIntent={paymentIntent}
            isPaymentElementReady={isPaymentElementReady}
            setIsPaymentElementReady={setIsPaymentElementReady}
            handleCloseModalFromButton={handleCloseModalFromButton}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PaymentDialog
