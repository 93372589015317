import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { GoogleOAuthProvider } from '@react-oauth/google'
import branch from 'branch-sdk'
import { AppBackground } from 'components/AppBackground'
import { ErrorBoundary } from 'components/ErrorBoundary'
import LoadingScreen from 'components/LoadingScreen'
import PlayerDetails from 'components/PlayerDetails'
import ProtectedRoute from 'components/ProtectedRoute'
import AppContext from 'contexts/App'
import 'global.css'
import GET__news from 'network-layer/endpoints/GET__news'
import { AdminPanel } from 'pages/AdminPanel'
import { ErrorPage } from 'pages/ErrorPage'
import { PrivacyPolicy } from 'pages/PrivacyPolicy'
import { TermsAndConditions } from 'pages/TermsAndConditions'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import getMotd from 'services/Motd/getMotd'
import getSecret from 'services/Payment/getSecret'
import getPlayers from 'services/Players/getPlayers'
import getRewards from 'services/Users/getRewards'
import getUser from 'services/Users/getUser'
import getCountries from 'services/Util/getCountries'
import { NewsItem, NotificationParams, Player, Reward, UserType } from 'types'
import * as consentModule from 'util/consent'
import { ConsentType } from 'util/consent'
import * as firebase from 'util/firebase'
import styles from './App.module.scss'
const Main = React.lazy(() => import('pages/Main/Main'))
const Redirect = React.lazy(() => import('pages/Redirect/Redirect'))
const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'))
const UserProfile = React.lazy(() => import('pages/UserProfile/UserProfile'))
const NewsPage = React.lazy(() => import('pages/NewsPage/NewsPage'))

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#233940',
    },
    secondary: {
      main: '#f2830f',
    },
  },
})

const App = () => {
  const [authToken, setAuthToken] = useState<string>('')
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<UserType>()
  const [appIsInitialized, setAppIsInitialized] = useState<boolean>(false)
  const [notification, setNotification] = useState<NotificationParams>(null)
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [stripeSecret, setStripeSecret] = useState<string>('')
  const [players, setPlayers] = useState<Array<Player>>([])
  const [countries, setCountries] = useState<Record<string, string>>()
  const [motd, setMotd] = useState<Record<string, string>>()
  const [isBIOInitialized, setIsBIOInitialized] = useState(false)
  const [consent, setConsent] = useState<ConsentType>(consentModule.getConsent())
  const [isAuthenticationDialogOpened, setIsAuthenticationDialogOpened] = useState(false)
  const [news, setNews] = useState<Array<NewsItem>>([])
  const [rewards, setRewards] = useState<Reward[]>([])

  // Initialize branch.io
  useEffect(() => {
    if (
      !isBIOInitialized &&
      process.env.REACT_APP_IS_REFERRAL_ENABLED &&
      consent &&
      consent.allowAllCookies
    ) {
      branch.init('key_live_orhc0rp2i7iKpVVQeufb7dgmAtdsiEYU', {}, (err, data) => {
        try {
          if (err) {
            throw new Error(JSON.stringify(err))
          }

          // data received on the branch.init callback
          const branchInitData: Record<string, any> | null = data

          // if there is no referring_link in the data received on the callback,
          // it is a regular access and we do nothing
          if (!branchInitData?.referring_link) {
            // the ref header and desktop url are only remove after login
            // localStorage.removeItem('refHeader').
            // localStorage.removeItem('redirectUrl')
            setIsBIOInitialized(true)
            return
          }

          // console.log('branchInitData', branchInitData)

          // get the session_id
          const branchSessionData = JSON.parse(sessionStorage.getItem('branch_session') || '{}')
          const sessionId = branchSessionData.session_id

          // store the session_id in the localStorage for later usage
          localStorage.setItem('refHeader', sessionId)

          // get the desktop_url
          const module = branchInitData.data_parsed['module']
          const playerId = branchInitData.data_parsed['playerId']
          const redirectUrl = `/${module}/${[playerId]}`

          // store the desktop_url in the local storage for later usage
          localStorage.setItem('redirectUrl', redirectUrl ? redirectUrl : `/players`)

          setIsBIOInitialized(true)
        } catch (error) {
          console.log('Branch.io Error: ', err)
          localStorage.removeItem('refHeader')
          setIsBIOInitialized(true)
        }
      })
    } else {
      setIsBIOInitialized(true)
    }
  }, [consent, isBIOInitialized])

  // Initialize firebase
  useEffect(() => {
    if (consent && consent.allowAllCookies) {
      firebase.initialize()
    }
  }, [consent])

  // Initialize app
  useEffect(() => {
    const initializeApp = async () => {
      try {
        // check to see if the user is authenticated
        const authToken = localStorage.getItem('authToken')

        const resMotd = await getMotd()
        setMotd(resMotd)

        const resNews = await GET__news()
        setNews(resNews)

        const resPlayers = await getPlayers({ authToken: authToken || undefined })
        setPlayers(resPlayers)

        if (authToken) {
          const resRewards = await getRewards({ authToken: authToken })
          const filteredRewards = resRewards.filter((el: Reward) => el.periods.length > 0)
          setRewards(filteredRewards)
        }

        const resCountries = await getCountries()
        setCountries(resCountries)

        if (authToken) {
          setAuthToken(authToken)
          const resUser = await getUser({ authToken })
          setUser(resUser)

          const resSecret = await getSecret({ authToken: authToken })
          setStripeSecret(resSecret.key)

          setIsAuthenticated(true)
        }

        setAppIsInitialized(true)
      } catch (error) {
        console.log('error initializing app', error)
        setUser(undefined)
        setIsAuthenticated(false)
        setAppIsInitialized(true)
        localStorage.removeItem('authToken')
      }
    }

    if (!appIsInitialized) {
      initializeApp()
    }
  }, [appIsInitialized])

  // Notifications manager
  useEffect(() => {
    if (notification) {
      setShowNotification(true)
    } else {
      setShowNotification(false)
    }
  }, [notification])

  // Notifications message handler
  const handleMessageClose = () => {
    setShowNotification(false)
    setTimeout(() => {
      setNotification(null)
    }, 100)
  }

  return (
    <GoogleOAuthProvider clientId="305905817016-bj3r1m2prcpm1dvt1qhrjpur57v7faoa.apps.googleusercontent.com">
      <div className={styles.row}>
        <div className={styles.column}>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <AppContext.Provider
                value={{
                  appIsInitialized,
                  authToken,
                  setAuthToken,
                  isAuthenticated,
                  setIsAuthenticated,
                  user,
                  setUser,
                  setNotification,
                  stripeSecret,
                  setStripeSecret,
                  players,
                  setPlayers,
                  setCountries,
                  countries,
                  motd,
                  setMotd,
                  analytics: '',
                  consent,
                  setConsent,
                  isAuthenticationDialogOpened,
                  setIsAuthenticationDialogOpened,
                  news,
                  setNews,
                  rewards,
                  setRewards,
                }}
              >
                <Routes>
                  {/* Error Page*/}
                  <Route path="*" element={<ErrorPage />} />

                  {/* Main */}
                  <Route
                    path="/"
                    element={
                      <React.Suspense fallback={<LoadingScreen />}>
                        <Main />
                      </React.Suspense>
                    }
                  >
                    {/* Main - Dashboard */}
                    <Route
                      path="/"
                      element={
                        <React.Suspense fallback={<LoadingScreen />}>
                          <Dashboard />
                        </React.Suspense>
                      }
                    />

                    {/* REDIRECT_URI */}
                    <Route
                      path="/redirect"
                      element={
                        <React.Suspense fallback={<LoadingScreen />}>
                          <Redirect />
                        </React.Suspense>
                      }
                    />

                    {/* Main - Player details */}
                    <Route
                      path="/player/:id"
                      element={
                        <React.Suspense fallback={<LoadingScreen />}>
                          <PlayerDetails />
                        </React.Suspense>
                      }
                    />

                    {/* Main - Terms and conditions */}
                    <Route
                      path="/terms-and-conditions"
                      element={
                        <React.Suspense fallback={<LoadingScreen />}>
                          <TermsAndConditions />
                        </React.Suspense>
                      }
                    />

                    {/* Main - Privacy policy */}
                    <Route
                      path="/privacy-policy"
                      element={
                        <React.Suspense fallback={<LoadingScreen />}>
                          <PrivacyPolicy />
                        </React.Suspense>
                      }
                    />

                    {/* Main - News Page */}
                    <Route
                      path="/news/:newsId"
                      element={
                        <React.Suspense fallback={<LoadingScreen />}>
                          <NewsPage />
                        </React.Suspense>
                      }
                    />

                    {/* --------- PROTECTED ROUTES --------- */}

                    {/* Main - User profile */}
                    <Route
                      index
                      path="/user-profile"
                      element={
                        <React.Suspense fallback={<LoadingScreen />}>
                          <ProtectedRoute>
                            <UserProfile />
                          </ProtectedRoute>
                        </React.Suspense>
                      }
                    />

                    {/* Main - Admin */}
                    {process.env.REACT_APP_IS_ADMIN_PANEL_ENABLED && (
                      <Route
                        path="/admin"
                        element={
                          <React.Suspense fallback={<LoadingScreen />}>
                            <ProtectedRoute adminRequired>
                              <AdminPanel />
                            </ProtectedRoute>
                          </React.Suspense>
                        }
                      />
                    )}
                  </Route>
                </Routes>
                <AppBackground />
              </AppContext.Provider>

              {/* Notifications */}
              <Snackbar
                open={showNotification ? true : false}
                autoHideDuration={6000}
                onClose={handleMessageClose}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'top',
                }}
                ClickAwayListenerProps={{ onClickAway: () => null }}
              >
                <Alert
                  onClose={handleMessageClose}
                  severity={notification ? notification.severity : 'success'}
                  sx={{ width: '100%' }}
                >
                  {notification ? notification.text : ''}
                </Alert>
              </Snackbar>
            </ThemeProvider>
          </ErrorBoundary>
        </div>
      </div>
    </GoogleOAuthProvider>
  )
}
export default App
