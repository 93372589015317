import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { log } from 'util/firebase'
import getAge from 'util/getAge'
import './index.css'
import { PlayerThumbnailProps } from './types'

const PlayerThumbnail = ({
  player,
  player: {
    shares: { availableShares, currentUser },
    aux: { fundedPercent, availabilityStatus, campaignLabel },
  },
  hasPlayerName = true,
  hasPlayerDetails = true,
  hasPlayerFundButton = false,
  hasCampaignExpiredButton = false,
  hasComingSoonButton = false,
  borderRadiusValue = 24,
  variant = 'dark',
  handleFundPlayer = () => {},
  activeSegment,
}: PlayerThumbnailProps) => {
  const navigate = useNavigate()

  if (!player) {
    return null
  }

  const {
    playerId,
    imageUrl = '',
    firstName = '',
    lastName = '',
    passportCountry = '',
    position = '',
    birthDate = '',
  } = player

  const positionArr = position.split(' ')
  const age = getAge(birthDate)

  const handleOnClick = () => {
    log({
      eventName: 'select_item',
      eventData: {
        playerId: playerId,
      },
    })

    if (variant === 'dark') {
      navigate(`/player/${playerId}`)
    }
  }

  return (
    <div
      className={cn('playerThumbnailContainer', `playerThumbnailContainer-${variant}`)}
      onClick={handleOnClick}
    >
      <div
        id="test"
        style={{
          position: 'absolute',
          width: '100%',
          height: '220px',
          // backgroundColor: '#B3F0C4',
          background:
            'linear-gradient(180deg, rgba(179,240,196,1) 0%, rgba(179,240,196,1) 32%, rgba(35,57,64,1) 100%)',
          top: '56px',
          zIndex: 0,
          borderRadius: '24px 24px 0 0',
        }}
      />
      <div
        style={{
          backgroundImage: `url('${imageUrl}')`,
          backgroundSize: '256px 260px',
          backgroundRepeat: 'no-repeat',
          height: '355px',
          width: '256px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            {activeSegment?.label === 'My Players' && currentUser && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '20px',
                  backgroundColor: '#E68A2F',
                  margin: '8px',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  style={{ color: '#24251E', margin: '4px 10px', fontWeight: 700 }}
                >
                  {currentUser} {currentUser === 1 ? 'share' : 'shares'}
                </Typography>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            {['coming soon', 'ongoing'].includes(availabilityStatus) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '20px',
                  backgroundColor: 'rgba(36, 37, 30, 0.30)',
                  margin: '8px',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" style={{ color: '#fff', margin: '4px 10px' }}>
                  {campaignLabel}
                </Typography>
              </div>
            )}
          </div>
        </div>

        <div
          className={cn(
            'playerThumbnailBlurContainer',
            `playerThumbnailBorderRadius-${borderRadiusValue}`,
          )}
        >
          {hasPlayerName && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '8px',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: '#fff',
                  textTransform: 'uppercase',
                  maxWidth: '224px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {firstName} <span style={{ color: '#A0B5BA' }}>{lastName}</span>
              </Typography>
            </div>
          )}
          {(availabilityStatus === 'ongoing' || availabilityStatus === 'expired') && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '2px',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <Typography
                    variant="body2"
                    style={{ color: variant === 'dark' ? '#A2A39B' : '#FAFCF2' }}
                  >
                    Progress: {fundedPercent}%
                  </Typography>
                </div>
                <div style={{ display: 'flex' }}>
                  <Typography
                    variant="body2"
                    style={{ color: variant === 'dark' ? '#A2A39B' : '#FAFCF2' }}
                  >
                    {availableShares} shares left
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '8px',
                }}
              >
                <div
                  style={{
                    height: '4px',
                    width: '100%',
                    backgroundColor: variant === 'dark' ? '#5D7279' : '#3C484C',
                    borderRadius: '20px',
                  }}
                >
                  <div
                    style={{
                      height: '4px',
                      width: `${fundedPercent}%`,
                      backgroundColor: `${
                        availabilityStatus === 'expired' ? '#C1CDD2' : '#FC532E'
                      }`,
                      borderRadius: '20px',
                    }}
                  ></div>
                </div>
              </div>
            </>
          )}
          {hasPlayerDetails && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '4px 8px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: '5 0 0',
                  alignSelf: 'stretch',
                  backgroundColor: '#3C484C',
                  borderRadius: '8px',
                  marginRight: '5px',
                }}
              >
                <Typography
                  variant="caption"
                  style={{
                    color: '#FAFCF2',
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {passportCountry}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '4px 8px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: '5 0 0',
                  alignSelf: 'stretch',
                  backgroundColor: '#3C484C',
                  borderRadius: '8px',
                  marginRight: '5px',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  variant="caption"
                  style={{
                    color: '#FAFCF2',
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {positionArr[0]}
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    color: '#FAFCF2',
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {positionArr[1]}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '4px 8px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: '1 0 0',
                  alignSelf: 'stretch',
                  backgroundColor: '#3C484C',
                  borderRadius: '8px',
                }}
              >
                <Typography variant="caption" style={{ color: '#FAFCF2' }}>
                  {age}
                </Typography>
              </div>
            </div>
          )}

          {hasPlayerFundButton && availabilityStatus === 'ongoing' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '8px',
              }}
            >
              <Button
                variant="contained"
                fullWidth
                style={{
                  borderRadius: '10px',
                  backgroundColor: '#FC532E',
                  color: '#3C484C',
                  fontWeight: 700,
                  boxShadow: 'none',
                }}
                onClick={handleFundPlayer}
              >
                Support Player
              </Button>
            </div>
          )}

          {hasCampaignExpiredButton && availabilityStatus === 'expired' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '8px',
              }}
            >
              <Button
                disabled
                variant="contained"
                fullWidth
                style={{
                  borderRadius: '10px',
                  backgroundColor: '#90A8B0',
                  color: '#3C484C',
                  fontWeight: 700,
                  boxShadow: 'none',
                }}
              >
                Campaign Completed
              </Button>
            </div>
          )}

          {hasComingSoonButton && availabilityStatus === 'coming soon' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '8px',
              }}
            >
              <Button
                disabled={true}
                variant="contained"
                fullWidth
                style={{
                  borderRadius: '10px',
                  backgroundColor: '#56B0EC',
                  color: '#fff',
                  fontWeight: 700,
                  boxShadow: 'none',
                }}
              >
                Coming soon
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PlayerThumbnail
