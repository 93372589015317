import generateConfig from "network-layer/config/generateConfig";
import { SaveUserParams } from "services/types";
import PATCH from "network-layer/verbs/PATCH";

const PATCH__users = async (params: SaveUserParams) => {
  const { authToken, ...rest } = params;
  return await PATCH("/users", generateConfig({ authToken }), { ...rest });
};

export default PATCH__users;
