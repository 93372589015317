interface ForbiddenError extends Error {
  body?: Record<string, unknown>
  responseStatus: number
}

// eslint-disable-next-line no-redeclare
class ForbiddenError extends Error {
  constructor(responseStatus: number, errorBody: Record<string, unknown>) {
    super('Could not process the request!')

    this.name = 'ForbiddenError'
    this.body = errorBody
    this.responseStatus = responseStatus

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenError)
    }
  }
}

export default ForbiddenError
