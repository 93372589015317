import ParseError from "network-layer/errors/ParseError";
import CustomError from "network-layer/errors/CustomError";
import UnknownSuccessCodeError from "network-layer/errors/UnknownSuccessCodeError";
import UnknownContentTypeHeaderError from "network-layer/errors/UnknownContentTypeHeaderError";

export default async function successBody(response: Response) {
  if (!response.ok) {
    let errorBody;

    try {
      errorBody = await response.json();
    } catch (parseError) {
      if (parseError instanceof Error) {
        throw new ParseError(parseError);
      }
    }

    throw CustomError(response.status, errorBody);
  }

  const contentTypeHeader = response.headers.get("Content-Type");

  switch (response.status) {
    case 200: {
      if (contentTypeHeader === "application/json") {
        try {
          const successBody = await response.json();
          return successBody;
        } catch (parseError) {
          if (parseError instanceof Error) {
            throw new ParseError(parseError);
          }
        }
      }

      throw new UnknownContentTypeHeaderError(contentTypeHeader);
    }
    case 201: {
      if (contentTypeHeader === "application/json") {
        try {
          const successBody = await response.json();
          return successBody;
        } catch (parseError) {
          if (parseError instanceof Error) {
            throw new ParseError(parseError);
          }
        }
      }

      return null;
    }
    case 204: {
      return null;
    }
    default:
      throw new UnknownSuccessCodeError(response.status);
  }
}
