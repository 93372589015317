import { VpnKey } from '@mui/icons-material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import BadgeIcon from '@mui/icons-material/Badge'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { DatePickerComponent } from 'components/DatePickerComponent'
import RequiredSign from 'components/Required'
import { SelectComponent } from 'components/SelectComponent'
import TextFieldComponent from 'components/TextFieldComponent'
import AppContext from 'contexts/App'
import dayjs, { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import resendEmail from 'services/Auth/resendEmail'
import verifyEmail from 'services/Auth/verifyEmail'
import saveUser from 'services/Users/saveUser'
import styles from './PaymentDialogCompleteInformationStep.module.scss'

type UserProfileSection = 'account settings' | 'personal info' | 'domicile address' | 'id'

const PaymentDialogCompleteInformationStep = ({
  setCurrentStep,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { authToken, user, setUser, countries } = useContext(AppContext)

  const [section, setSection] = useState<UserProfileSection>('account settings')

  // Account validation
  const [isAccountVerified, setIsAccountVerified] = useState<boolean | undefined>()
  const [validationCode, setValidationCode] = useState<string>('')
  const [resendEmailRequestError, setResendEmailRequestError] = useState(false)
  const [resendEmailSuccessMessage, setResendEmailSuccessMessage] = useState('')
  const [validateEmailRequestError, setValidateEmailRequestError] = useState(false)
  const [validateEmailSuccessMessage, setValidateEmailSuccessMessage] = useState('')

  // Account settings fields
  const [email, setEmail] = useState<string>('')

  // Personal Info Fields
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [birthCity, setBirthCity] = useState<string>('')
  const [nationality, setNationality] = useState<string>('')
  const [birthDate, setBirthDate] = useState<string>('')

  // Domicile address fields
  const [street, setStreet] = useState<string>('')
  const [streetNumber, setStreetNumber] = useState<string>('')
  const [apartment, setApartment] = useState<string>('')
  const [zipcode, setZipcode] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [county, setCounty] = useState<string>('')
  const [country, setCountry] = useState<string>('')

  // ID fields
  const [idType, setIdType] = useState<string>('')
  const [idNumber, setIdNumber] = useState<string>('')
  const [idCountry, setIdCountry] = useState<string>('')
  const [idExpirationDate, setIdExpirationDate] = useState<string>('')

  const [isAccountSettingsComplete, setIsAccountSettingsComplete] = useState<boolean>(false)
  const [isPersonalInfoComplete, setIsPersonalInfoComplete] = useState<boolean>(false)
  const [isDomicileAddressComplete, setIsDomicileAddressComplete] = useState<boolean>(false)
  const [isIdFieldsComplete, setIsIdFieldsComplete] = useState<boolean>(false)
  const [isIdExpired, setIsIdExpired] = useState<boolean>(false)

  console.log('isIdExpired', isIdExpired)

  const [updatedUserInfo, setUpdatedUserInfo] = useState<Record<string, string | null>>({})

  // CHECK SECTIONS ARE COMPLETE
  useEffect(() => {
    setIsAccountSettingsComplete(email !== '' && Boolean(isAccountVerified))
  }, [email, isAccountVerified])

  useEffect(() => {
    setIsPersonalInfoComplete(
      ![firstName, lastName, birthCity, nationality, birthDate].includes(''),
    )
  }, [firstName, lastName, birthCity, nationality, birthDate])

  useEffect(() => {
    setIsDomicileAddressComplete(
      ![street, streetNumber, zipcode, city, county, country].includes(''),
    )
  }, [street, streetNumber, zipcode, city, county, country])

  useEffect(() => {
    setIsIdFieldsComplete(![idType, idNumber, idCountry, idExpirationDate].includes(''))
    if (new Date(idExpirationDate) < new Date()) {
      setIsIdExpired(true)
    } else {
      setIsIdExpired(false)
    }
  }, [idType, idNumber, idCountry, idExpirationDate])

  // Hydrate based on user
  useEffect(() => {
    // Account validation
    setIsAccountVerified(user?.isAccountVerified)

    // Account settings fields
    setEmail(user?.email || '')

    setFirstName(user?.firstName || '')
    setLastName(user?.lastName || '')
    setBirthCity(user?.birthCity || '')
    setNationality(user?.nationality || '')
    setBirthDate(user?.birthDate || '')

    // Domicile address fields
    setStreet(user?.street || '')
    setStreetNumber(user?.streetNumber || '')
    setApartment(user?.apartment || '')
    setZipcode(user?.zipcode || '')
    setCity(user?.city || '')
    setCounty(user?.county || '')
    setCountry(user?.country || '')

    // ID fields
    setIdType(user?.idType || '')
    setIdNumber(user?.idNumber || '')
    setIdCountry(user?.idCountry || '')
    setIdExpirationDate(user?.idExpirationDate || '')
  }, [user])

  useEffect(() => {
    // TODO : validation
    setUpdatedUserInfo({
      firstName: firstName ? firstName : null,
      lastName: lastName ? lastName : null,
      birthCity: birthCity ? birthCity : null,
      nationality: nationality ? nationality : null,
      birthDate: birthDate ? birthDate : null,
      street: street ? street : null,
      streetNumber: streetNumber ? streetNumber : null,
      apartment: apartment ? apartment : null,
      zipcode: zipcode ? zipcode : null,
      city: city ? city : null,
      county: county ? county : null,
      country: country ? country : null,
      idType: idType ? idType : null,
      idNumber: idNumber && idNumber.length >= 5 ? idNumber : null,
      idCountry: idCountry ? idCountry : null,
      idExpirationDate: idExpirationDate ? idExpirationDate : null,
    })
  }, [
    apartment,
    birthCity,
    birthDate,
    city,
    country,
    county,
    firstName,
    idCountry,
    idExpirationDate,
    idNumber,
    idType,
    lastName,
    nationality,
    street,
    streetNumber,
    zipcode,
  ])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasSaveUserError, setHasSaveUserError] = useState<boolean>(false)

  const [isResendEmailLoading, setIsResendEmailLoading] = useState(false)
  const [isValidatingEmailLoading, setIsValidatingEmailLoading] = useState(false)

  const handleSaveUser = async () => {
    setIsLoading(true)
    setHasSaveUserError(false)
    try {
      const resUser = await saveUser({
        ...updatedUserInfo,
        authToken: authToken!,
      })
      setUser(resUser)
      setIsLoading(false)
    } catch (error) {
      // TODO: error handling
      // console.log(error)
      setIsLoading(false)
      setHasSaveUserError(true)
    }
  }

  const handleResendEmail = async () => {
    try {
      setResendEmailRequestError(false)
      setResendEmailSuccessMessage('')
      setIsResendEmailLoading(true)
      await resendEmail({
        email,
      })
      setResendEmailSuccessMessage('A new validation code has been emailed to you successfully!')
      setIsResendEmailLoading(false)
    } catch (error) {
      // TODO: error handling
      // console.log(error)
      setResendEmailRequestError(true)
      setResendEmailSuccessMessage('')
      setIsResendEmailLoading(false)
    }
  }

  const handleValidateEmail = async () => {
    try {
      setValidateEmailRequestError(false)
      setValidateEmailSuccessMessage('')
      setIsValidatingEmailLoading(true)
      await verifyEmail({
        email,
        token: validationCode,
      })
      // setValidateEmailSuccessMessage('Email has been validated successfully!')
      setUser({
        ...user!,
        isAccountVerified: true,
      })
      // setIsValidatingEmailLoading(false)
    } catch (error) {
      // TODO: error handling
      // console.log(error)
      setValidateEmailRequestError(true)
      setValidateEmailSuccessMessage('')
      setIsValidatingEmailLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '450px',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '32px' }}>
          <Typography variant="body1" style={{ fontWeight: 600, marginRight: '16px' }}>
            {user?.profileCompleted
              ? 'Profile completed. You can now proceeed to the next step.'
              : 'All the required information must be provided in order to proceed to the next step.'}
          </Typography>
        </div>
        <div className={styles.container}>
          {/* left column */}
          <div className={styles.container_leftColumn}>
            <MenuItem
              style={{
                borderRadius: '20px',
                color: '#FAFCF2',
              }}
              onClick={() => {
                setSection('account settings')
              }}
              disabled={isLoading}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" style={{ color: '#FAFCF2' }} />
              </ListItemIcon>
              <ListItemText>
                Account settings {!isAccountSettingsComplete && <RequiredSign variant="dialog" />}
              </ListItemText>
            </MenuItem>
            <MenuItem
              style={{
                borderRadius: '20px',
                color: '#FAFCF2',
              }}
              onClick={() => {
                setSection('personal info')
              }}
              disabled={isLoading || !user?.isAccountVerified}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" style={{ color: '#FAFCF2' }} />
              </ListItemIcon>
              <ListItemText>
                Personal info {!isPersonalInfoComplete && <RequiredSign variant="dialog" />}
              </ListItemText>
            </MenuItem>
            <MenuItem
              style={{
                borderRadius: '20px',
                color: '#FAFCF2',
              }}
              onClick={() => {
                setSection('domicile address')
              }}
              disabled={isLoading || !user?.isAccountVerified}
            >
              <ListItemIcon>
                <HomeIcon fontSize="small" style={{ color: '#FAFCF2' }} />
              </ListItemIcon>
              <ListItemText>
                Domicile Address {!isDomicileAddressComplete && <RequiredSign variant="dialog" />}
              </ListItemText>
            </MenuItem>
            <MenuItem
              style={{
                borderRadius: '20px',
                color: '#FAFCF2',
              }}
              onClick={() => {
                setSection('id')
              }}
              disabled={isLoading || !user?.isAccountVerified}
            >
              <ListItemIcon>
                <BadgeIcon fontSize="small" style={{ color: '#FAFCF2' }} />
              </ListItemIcon>
              <ListItemText>
                ID {(!isIdFieldsComplete || isIdExpired) && <RequiredSign variant="dialog" />}
              </ListItemText>
            </MenuItem>
          </div>

          {/* right column */}
          <div className={styles.container_rightColumn}>
            <Typography variant="h6" fontWeight={700} mb={1}>
              {' '}
              {section === 'account settings' && 'Account settings'}
              {section === 'personal info' && 'Personal info'}
              {section === 'domicile address' && 'Domicile address'}
              {section === 'id' && 'ID'}
            </Typography>
            {/* ACCOUNT SETTINGS */}
            {section === 'account settings' && (
              <>
                {isAccountVerified ? (
                  <div style={{ marginBottom: '24px' }}>
                    <Typography variant="body1" mb={2}>
                      Your e-mail address has been verified.
                    </Typography>
                    <div className={styles.container_rightColumn_row}>
                      <div className={styles.singleCell}>
                        <TextFieldComponent
                          disabled={true}
                          fieldVariant="login"
                          required={email === ''}
                          label="Account Email"
                          value={email}
                          onChange={({ target }) => {
                            setEmail(target.value)
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ marginBottom: '24px' }}>
                    <Typography variant="body1" mb={2}>
                      Email address verification is required before proceeding with the rest of your
                      personal information. In order to do this, request an e-mail validation code,
                      which will be sent to the e-mail address specified above.
                    </Typography>
                    <div className={styles.container_rightColumn_row}>
                      <div className={styles.cellLeft}>
                        <TextFieldComponent
                          disabled={true}
                          fieldVariant="login"
                          required={email === ''}
                          label="Account Email"
                          value={email}
                          onChange={({ target }) => {
                            setEmail(target.value)
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                          helperText={email === '' ? 'This is a required field' : ''}
                        />
                      </div>
                      <div className={styles.cellRight}>
                        <Button
                          disabled={isResendEmailLoading || isValidatingEmailLoading}
                          variant="contained"
                          sx={{
                            height: 44,
                            borderRadius: '10px',
                            backgroundColor: '#3C484C',
                            color: '#F2F2F3',
                            marginBottom: '8px',
                            marginTop: '27px',
                            '&.Mui-disabled': {
                              background: '#3B5F66',
                              color: '#c0c0c0',
                            },
                          }}
                          size="small"
                          fullWidth
                          onClick={handleResendEmail}
                        >
                          request validation code
                        </Button>
                      </div>
                    </div>
                    {isResendEmailLoading ? (
                      <Typography variant="body2" mb={1}>
                        Please wait ...
                      </Typography>
                    ) : resendEmailRequestError ? (
                      <Typography variant="body2" color="#FC532E" mb={1}>
                        An error was encountered while trying to process the request. Please try
                        again later.
                      </Typography>
                    ) : resendEmailSuccessMessage ? (
                      <Typography variant="body2" color="#FFF" mb={1}>
                        {resendEmailSuccessMessage}
                      </Typography>
                    ) : null}
                    <Typography variant="body1" mb={2}>
                      After receiving it, please enter it in the field below in order to complete
                      the process.
                    </Typography>
                    <div className={styles.container_rightColumn_row}>
                      <div className={styles.cellLeft}>
                        <TextFieldComponent
                          fieldVariant="login"
                          required={validationCode === ''}
                          label="Validation Code"
                          value={validationCode}
                          onChange={({ target }) => {
                            setValidationCode(target.value)
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <VpnKey />
                              </InputAdornment>
                            ),
                          }}
                          helperText={email === '' ? 'This is a required field' : ''}
                        />
                      </div>
                      <div className={styles.cellRight}>
                        <Button
                          disabled={isResendEmailLoading || isValidatingEmailLoading}
                          variant="contained"
                          sx={{
                            marginTop: '27px',
                            height: 44,
                            borderRadius: '10px',
                            backgroundColor: '#3C484C',
                            color: '#F2F2F3',
                            marginBottom: '8px',
                            '&.Mui-disabled': {
                              background: '#3B5F66',
                              color: '#c0c0c0',
                            },
                          }}
                          size="small"
                          fullWidth
                          onClick={handleValidateEmail}
                        >
                          verify email
                        </Button>
                      </div>
                    </div>
                    {isValidatingEmailLoading ? (
                      <Typography variant="body2" mb={1}>
                        Please wait ...
                      </Typography>
                    ) : validateEmailRequestError ? (
                      <Typography variant="body2" color="#FC532E" mb={1}>
                        An error was encountered while trying to process your validation code.
                        Please try again later.
                      </Typography>
                    ) : validateEmailSuccessMessage ? (
                      <Typography variant="body2" color="#038900" mb={1}>
                        {validateEmailSuccessMessage}
                      </Typography>
                    ) : null}
                  </div>
                )}
              </>
              // <div className={styles.container_rightColumn_row}>
              //   <div className={styles.singleCell}>
              //     <TextFieldComponent
              //       fieldVariant="login"
              //       disabled={true}
              //       required={email === ''}
              //       label="Account Email"
              //       value={email}
              //       onChange={({ target }) => {
              //         setEmail(target.value)
              //       }}
              //       icon={<EmailIcon />}
              //       helperText={email === '' ? 'This is a required field' : ''}
              //     />
              //   </div>
              // </div>
            )}

            {/* PERSONAL INFO */}
            {section === 'personal info' && (
              <>
                <div className={styles.container_rightColumn_row}>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      required={firstName === ''}
                      label="First name"
                      value={firstName}
                      onChange={({ target }) => {
                        setFirstName(target.value)
                      }}
                      helperText={firstName === '' ? 'This is a required field' : ''}
                      disabled={isLoading}
                    />
                  </div>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      required={lastName === ''}
                      label="Last name"
                      value={lastName}
                      onChange={({ target }) => {
                        setLastName(target.value)
                      }}
                      helperText={lastName === '' ? 'This is a required field' : ''}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className={styles.container_rightColumn_row}>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      required={birthCity === ''}
                      label="City of birth"
                      value={birthCity}
                      onChange={({ target }) => {
                        setBirthCity(target.value)
                      }}
                      helperText={birthCity === '' ? 'This is a required field' : ''}
                      disabled={isLoading}
                    />
                  </div>

                  <div className={styles.cellLeft}>
                    <SelectComponent
                      fieldVariant="login"
                      required={nationality === ''}
                      label="Nationality"
                      value={nationality}
                      onChange={({ target }) => {
                        setNationality(target.value)
                      }}
                      helperText={nationality === '' ? 'This is a required field' : ''}
                      disabled={isLoading}
                    >
                      {Object.keys(countries || {}).map((key) => {
                        if (key) {
                          return (
                            <MenuItem key={`nationality-${key}`} value={key}>
                              {countries ? countries[key] : 'N/A'}
                            </MenuItem>
                          )
                        }
                        return null
                      })}
                    </SelectComponent>
                  </div>
                </div>
                <div className={styles.container_rightColumn_row}>
                  <div className={styles.singleCell}>
                    <DatePickerComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      value={dayjs(birthDate)}
                      onChange={(newValue: Dayjs | null) => {
                        if (newValue) {
                          setBirthDate(
                            `${newValue.get('year')}-${newValue.get('month') + 1}-${newValue.get(
                              'date',
                            )}`,
                          )
                        } else {
                          setBirthDate('')
                        }
                      }}
                      label="Date of birth"
                      required={birthDate === ''}
                      helperText={birthDate === '' ? 'This is a required field' : ''}
                    />
                  </div>
                </div>
              </>
            )}

            {/* DOMICILE ADDRESS */}
            {section === 'domicile address' && (
              <>
                <div className={styles.container_rightColumn_row}>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={street === ''}
                      label="Street"
                      value={street}
                      onChange={({ target }) => {
                        setStreet(target.value)
                      }}
                      helperText={street === '' ? 'This is a required field' : ''}
                    />
                  </div>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={streetNumber === ''}
                      label="Number"
                      value={streetNumber}
                      onChange={({ target }) => {
                        setStreetNumber(target.value)
                      }}
                      helperText={streetNumber === '' ? 'This is a required field' : ''}
                    />
                  </div>
                </div>
                <div className={styles.container_rightColumn_row}>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      label="Apartment"
                      value={apartment}
                      onChange={({ target }) => {
                        setApartment(target.value)
                      }}
                    />
                  </div>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={zipcode === ''}
                      label="Postal Code"
                      value={zipcode}
                      onChange={({ target }) => {
                        setZipcode(target.value)
                      }}
                      helperText={zipcode === '' ? 'This is a required field' : ''}
                    />
                  </div>
                </div>

                <div className={styles.container_rightColumn_row}>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={city === ''}
                      label="City"
                      value={city}
                      onChange={({ target }) => {
                        setCity(target.value)
                      }}
                      helperText={city === '' ? 'This is a required field' : ''}
                    />
                  </div>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={county === ''}
                      label="County"
                      value={county}
                      onChange={({ target }) => {
                        setCounty(target.value)
                      }}
                      helperText={county === '' ? 'This is a required field' : ''}
                    />
                  </div>
                </div>

                <div className={styles.container_rightColumn_row}>
                  <div className={styles.singleCell}>
                    <SelectComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={country === ''}
                      label="Country"
                      value={country}
                      onChange={({ target }) => {
                        setCountry(target.value)
                      }}
                      helperText={country === '' ? 'This is a required field' : ''}
                    >
                      {Object.keys(countries || {}).map((key) => {
                        if (key) {
                          return (
                            <MenuItem key={`domicile-country-${key}`} value={key}>
                              {countries ? countries[key] : 'N/A'}
                            </MenuItem>
                          )
                        }
                        return null
                      })}
                    </SelectComponent>
                  </div>
                </div>
              </>
            )}

            {/* ID */}
            {section === 'id' && (
              <>
                <div className={styles.container_rightColumn_row}>
                  <div className={styles.cellLeft}>
                    <SelectComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={idType === ''}
                      label="ID Type"
                      value={idType}
                      onChange={({ target }) => {
                        setIdType(target.value)
                      }}
                      helperText={idType === '' ? 'This is a required field' : ''}
                    >
                      <MenuItem value="nationalId">National ID</MenuItem>
                      <MenuItem value="passport">Passport</MenuItem>
                    </SelectComponent>
                  </div>
                  <div className={styles.cellLeft}>
                    <TextFieldComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={idNumber === '' || idNumber.length < 5}
                      label="ID Number"
                      value={idNumber}
                      onChange={({ target }) => {
                        setIdNumber(target.value)
                      }}
                      helperText={
                        idNumber === ''
                          ? 'This is a required field'
                          : idNumber.length < 5
                            ? 'The ID number must have at least 5 characters'
                            : ''
                      }
                    />
                  </div>
                </div>
                <div className={styles.container_rightColumn_row}>
                  <div className={styles.cellLeft}>
                    <SelectComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      required={idCountry === ''}
                      label="Country"
                      value={idCountry}
                      onChange={({ target }) => {
                        setIdCountry(target.value)
                      }}
                      helperText={idCountry === '' ? 'This is a required field' : ''}
                    >
                      {Object.keys(countries || {}).map((key) => {
                        if (key) {
                          return (
                            <MenuItem key={`idCountry-${key}`} value={key}>
                              {countries ? countries[key] : 'N/A'}
                            </MenuItem>
                          )
                        }
                        return null
                      })}
                    </SelectComponent>
                  </div>

                  <div className={styles.cellLeft}>
                    <DatePickerComponent
                      fieldVariant="login"
                      disabled={isLoading}
                      value={dayjs(idExpirationDate)}
                      onChange={(newValue: Dayjs | null) => {
                        if (newValue) {
                          setIdExpirationDate(
                            `${newValue.get('year')}-${newValue.get('month') + 1}-${newValue.get(
                              'date',
                            )}`,
                          )
                        } else {
                          setIdExpirationDate('')
                        }
                      }}
                      label="Expires"
                      required={idExpirationDate === '' || new Date(idExpirationDate) < new Date()}
                      helperText={
                        idExpirationDate === ''
                          ? 'This is a required field'
                          : new Date(idExpirationDate) < new Date()
                            ? 'ID is expired'
                            : ''
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '4px',
          justifyContent: 'flex-end',
        }}
      >
        {hasSaveUserError && (
          <div
            style={{
              width: '540px',
              marginRight: '8px',
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: 500, color: '#fff', marginRight: '16px' }}
            >
              An error was encountered. Please make sure you have filled all the required fields
              with the correct information.
            </Typography>
          </div>
        )}
        <Button
          variant="contained"
          disableRipple={true}
          size="large"
          sx={{
            padding: '16px',
            borderRadius: '10px',
            backgroundColor: '#3C484C',
            boxShadow: 'none',
            color: '#F2F2F3',
            marginRight: '16px',
          }}
          onClick={handleSaveUser}
        >
          <Typography>Save Information</Typography>
        </Button>
        <Button
          disabled={!user?.profileCompleted}
          variant="contained"
          disableRipple={true}
          size="large"
          sx={{
            padding: '16px',
            borderRadius: '10px',
            backgroundColor: '#3C484C',
            boxShadow: 'none',
            color: '#F2F2F3',
          }}
          onClick={() => {
            setCurrentStep('Confirm Purchase')
          }}
          endIcon={<ArrowForwardIcon />}
        >
          <Typography>Confirm Purchase</Typography>
        </Button>
      </div>
    </div>
  )
}

export default PaymentDialogCompleteInformationStep
