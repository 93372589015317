import generateConfig from 'network-layer/config/generateConfig'
import GET from 'network-layer/verbs/GET'
import { GetPlayersParams } from 'services/types'

export default async function GET__players(params: GetPlayersParams) {
  const { authToken, isAdmin } = params

  const url = isAdmin ? '/admin/players' : '/players'

  return await GET(url, generateConfig({ authToken }))
}
