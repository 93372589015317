import generateConfig from 'network-layer/config/generateConfig'
import POST from 'network-layer/verbs/POST'
import { SavePlayerRewardsParams } from 'services/types'

const POST__resetPassword = async (params: SavePlayerRewardsParams) => {
  const { authToken, playerId, ...rest } = params

  return await POST(`/admin/players/${playerId}/rewards`, generateConfig({ authToken }), {
    ...rest,
  })
}

export default POST__resetPassword
