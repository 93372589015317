import PageContent from 'components/PageContent'
import { PageHeaderTitle } from 'components/PageHeaderTitle'

const TermsAndConditions = () => {
  return (
    <PageContent>
      <PageHeaderTitle>Terms And Conditions</PageHeaderTitle>
      <div>
        <p>
          <span>
            <strong>Last updated: June 12, 2023</strong>
          </span>
        </p>
        <p>
          <strong>1. INTRODUCTION</strong>
        </p>
        <p>
          <strong>Nordensa Football SRL</strong>, registered with the Romanian Trade Registry Office
          next to the Cluj Court under no. J12/6438/2022, fiscal code 47032637, Paris St. no. 102,
          Cluj-Napoca (referred to as either <strong>"Nordensa"</strong>, <strong>"We"</strong>,{' '}
          <strong>"Us"</strong> or <strong>"Our"</strong> in these Terms and Conditions) is a
          technology company active worldwide which&nbsp;allows fans to support football players -
          who are scouted and vetted by the Nordensa scouting team – via an online platform (the{' '}
          <strong>“Nordensa Platform”</strong>) which is dedicated to football fans who:
        </p>
        <ul data-rte-list="default">
          <li>
            <p>
              are interested in being up to date with up-and-coming talent and to receive football
              data and scouting reports regarding promising players scouted by Nordensa;
            </p>
          </li>
          <li>
            <p>
              are interested in partnering with Nordensa in its’ business by making financial
              contributions to such partnership, so to support the career of a player and create a
              chance for that player to pursue a professional career
            </p>
          </li>
        </ul>
        <p>
          These Terms and Conditions were issued by Nordensa on <strong>June 12, 2023.</strong>
          &nbsp;
        </p>
        <p>
          The Nordensa Platform can be used by a person who has been approved by Nordensa as a user
          of the Nordensa Platform, in that person’s capacity as a Registered User or a Backer (each
          of these terms has the meaning given to them below, and collectively will be referred to
          as a <strong>“User”</strong>, or <strong>“You”</strong>, as further detailed below).&nbsp;
        </p>
        <p>
          Nordensa reserves the right to deny a User from using the Nordensa Platform. While
          assessing a User for approval, Nordensa can, inter alia, take into account possible formal
          restrictions applicable with regard to the User’s domicile country jurisdiction. In case
          the User is not approved by Nordensa, any User Account (as defined below) opened by the
          User will be promptly deactivated and deleted.
        </p>
        <p></p>
        <p>
          <strong>2. ACKNOWLEDGMENT</strong>
        </p>
        <p>
          These are the Terms and Conditions governing the use of this Service (as defined below)
          and the agreement that operates between You and Nordensa. These Terms and Conditions set
          out the rights and obligations of all users regarding the use of the Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on Your acceptance of and compliance
          with these Terms and Conditions. These Terms and Conditions apply to all visitors, users
          and others who access or use the Service.
        </p>
        <p>
          By accessing or using the Service You agree to be bound by these Terms and Conditions. If
          You disagree with any part of these Terms and Conditions then You may not access the
          Service.
        </p>
        <p>
          Your access to and use of the Service is also conditioned on Your acceptance of and
          compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our
          policies and procedures on the collection, use and disclosure of Your personal information
          when You use the Application or the Website and tells You about Your privacy rights and
          how the law protects You. Please read Our <a href="/privacy-policy">Privacy Policy</a>{' '}
          carefully before using Our Service available at{' '}
          <strong>nordensa.com/privacy-policy</strong>.<br />
          <br />
          <br />
          <strong>
            <br />
            3. BUSINESS MODEL
          </strong>
        </p>
        <p>
          Nordensa is conducting a fan-centred business that seeks to identify young and talented
          football players (the <strong>“Players”</strong>) via Nordensa’s team of (data) scouts and
          data systems and algorithms with the aim of creating a chance for them to showcase their
          talent to a professional football club, ideally in Europe (the <strong>“Clubs”</strong>),
          inter alia by entering into a partnership with backers (see below) and by (i) providing
          certain services (exemplified in section 3.2 below) to the Player and (ii) by means of a
          funded trial with Clubs with which Nordensa has concluded scouting agreements.{' '}
        </p>
        <p>
          With the aim of conducting its business activity, in relation to each Player identified by
          Nordensa, Nordensa envisages to partner with football fans, by entering into a joint
          venture agreement (a <strong>“JV Agreement”</strong>) with fans who would be willing to
          participate in Nordensa’s business by contributing funds which are needed to provide the
          required services to the Player (i.e. create visibility for the athlete towards fans and
          clubs via Nordensa Platforms, help build a personal brand, set up and take care of social
          media platforms &amp; create content, mental health coaching, organize and cover financial
          costs of a trial/trials etc.).(the <strong>“Player Services”</strong>)
        </p>
        <p>
          For the purpose of attracting potential Backers to partner with Nordensa in order to
          provide the Player Services, Nordensa will post information regarding each such Player on
          the Nordensa Platform (a <strong>“Support Campaign”</strong>).
        </p>
        <p>
          Nordensa, on behalf of the Nordensa Joint Venture (the <strong>“JV”</strong>) will enter
          into a private agreement with a Player, by means of which, depending on the amount of
          money the JV raises and dedicates to providing the Player Services to the respective
          Player, the JV Partners (as defined below) will be entitled, if the Player is successful,
          to receive the equivalent of a certain percentage of the Players’ football related
          revenues stemming from contracts signed in the next five years.
        </p>
        <p>
          Separately, Nordensa, on behalf of the JV, only where necessary, and depending on the
          amount raised at JV level, might enter into a separate agreement with a Club which is
          interested in signing the Player, foreseeing the payment of an incentive to said club to
          sign the Player.
        </p>
        <p></p>
        <p>
          <strong>4. RISK INFORMATION</strong>
        </p>
        <p>
          Nordensa reminds the Users of the Nordensa Platform that partnering with Nordensa in a JV,
          by signing a JV Agreement for the supporting Players is not a risk-free endeavour. With
          respect to each Campaign (as defined below), and the Player thereby concerned, Nordensa
          will share with each Backer only the specific share of the profits from the Player Service
          Fee (as defined below) to which they are entitled under the conditions of the Campaign and
          the JV Agreement. This means that each Backer, in their partnership with Nordensa with
          respect to a certain Campaign, can receive less (or no) monetary benefits than the funds
          they have contributed to the partnership with Nordensa.
        </p>
        <p></p>
        <p>
          <strong>5. INTERPRETATION</strong>
        </p>
        <p>
          The words of which the initial letter is capitalized have the meanings given to them in
          section [6] (Definitions).&nbsp;
        </p>
        <p>
          The following definitions shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <p></p>
        <p>
          <strong>6. DEFINITIONS</strong>
        </p>
        <p>For the purposes of these Terms and Conditions:</p>
        <p>
          <strong>“Affiliate”&nbsp;</strong>means an entity that controls, is controlled by or is
          under common control with a party, where "control" means ownership of 50% or more of the
          shares, equity interest or other securities entitled to vote for election of directors or
          other managing authority.
        </p>
        <p>
          <strong>“Backer”&nbsp;</strong>means, with regard to a specific Support Campaign, a
          Registered User who has also partnered with Nordensa with regard to the relevant Player,
          and has duly signed the relevant JV Agreement and fulfilled his/her obligations under the
          JV Agreement.
        </p>
        <p>
          <strong>“Support Campaign”&nbsp;</strong>has the meaning given above.
        </p>
        <p>
          <strong>“Club”&nbsp;</strong>means a collaborating football club, who has interest in a
          particular Player
        </p>
        <p>
          <strong>“Country”&nbsp;</strong>refers to Romania
        </p>
        <p>
          <strong>“JV Agreement”</strong> has the meaning given above.
        </p>
        <p>
          <strong>“JV”&nbsp;</strong>has the meaning given above.
        </p>
        <p>
          <strong>“JV Partners”</strong> means Nordensa and the Backers contributing to a specific
          JV
        </p>
        <p>
          <strong>“Nordensa Platform”&nbsp;</strong>refers to the Nordensa Platform developed by
          Nordensa which is available as a FREE download on the Apple App Store, Google Play Store
          and as a web application
        </p>
        <p>
          <strong>“Player”&nbsp;</strong>means a football player who has been vetted by Nordensa’s
          team of scouts and listed on the Nordensa Platform for the purposes of a Campaign.&nbsp;
        </p>
        <p>
          <strong>“Player Services”&nbsp;</strong>has the meaning given above.
        </p>
        <p>
          <strong>“Player Service Fee”&nbsp;</strong>means the equivalent of a percentage of the
          Player’s football-related revenue, which will be payable by the Player to Nordensa
          representing the relevant JV, and the profit resulting from such fee distributed to the JV
          Partners.&nbsp;
        </p>
        <p>
          <strong>“Registered User”&nbsp;</strong>means a User who as created a User Account, but
          has not yet partnered with Nordensa with regard to a Player, so is not yet a Backer.
        </p>
        <p>
          <strong>“Service”&nbsp;</strong>refers to the&nbsp; services rendered by Us to the Users
          via the Nordensa Platform (which will be hosted both online and via a mobile application),
          including the services provided by Nordensa to its Backers in relation to the Campaigns,
          JV Agreements.
        </p>
        <p>
          <strong>“Terms and Conditions”&nbsp;</strong>(also referred as "Terms") mean these Terms
          and Conditions that form the entire agreement between the Users and the Company regarding
          the use of the Service.
        </p>
        <p>
          <strong>“Third-party Social Media Service”&nbsp;</strong>means any services or content
          (including data, information, products or services) provided by a third-party that may be
          displayed, included or made available by the Service.
        </p>
        <p>
          <strong>“Unregistered User”&nbsp;</strong>means a visitor to the site, who has not yet
          created a User Account
        </p>
        <p>
          <strong>“User Account”&nbsp;</strong>means the account that a Registered User or , a
          Backer, [a Player or a Club] creates, in their own name, on the Nordensa Platform.
        </p>
        <p>
          <strong>“User”</strong> or <strong>“You”&nbsp;</strong>means the individual accessing or
          using the Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable, including Registered Users
          and Backers, in the conditions laid down in Section [12] (“AS IS” and “AS AVAILABLE”
          Disclaimer)
        </p>
        <p>
          <strong>“Website”&nbsp;</strong>refers to the Nordensa presentation / corporate website
          accessible at{' '}
          <a href="http://www.nordensa.com/">
            <span>www.nordensa.com</span>
          </a>
        </p>
        <p></p>
        <p>
          <strong>7. USE OF THE NORDENSA PLATFORM</strong>
        </p>
        <p>
          The Nordensa Platform is available as an app that can be freely downloaded on Apple App
          Store and Google Play Store. In addition, it is also available as a web-application.
        </p>
        <p>
          All Users who access Nordensa Platform or get to download the Nordensa app need to create
          an account using their email address whilst sharing their name and country of residence in
          order to gain access to the app’s content and features.
        </p>
        <p>Registered Users may perform the following actions on the Nordensa Platform:</p>
        <ul data-rte-list="default">
          <li>
            <p>
              Customize their user account by adding a profile picture and other types of personal
              information in the dedicated sections;
            </p>
          </li>
          <li>
            <p>
              Interact with other Registered Users and with the Nordensa Platform through posting
              comments and liking others’ activity on the Nordensa Platform.
            </p>
          </li>
          <li>
            <p>Participate in a JV Agreement pursuant to article 7.5.</p>
          </li>
        </ul>
        <p>
          Registered Users are solely responsible for their own behavior while accessing and using
          the Nordensa Platform. You agree to use the Platform only for purposes that are legal and
          in accordance with these Terms and Conditions and any applicable laws or
          regulations.&nbsp;
        </p>
        <p>
          Any deviation the aforementioned obligation may be sanctioned by deleting the User account
          or temporarily restricting access to the Nordensa Platform.
        </p>
        <p>
          <br />
          The content posted on the Nordensa Platform belongs to the Registered Users who posted it.
          By posting on the Platform, Registered users grant Nordensa a non-exclusive license, free
          of charge, unlimited in time and territory for the reproduction, publication, transmission
          and dissemination of the content.&nbsp;
        </p>
        <p>
          Registered Users maintain all responsibility towards the accuracy, lawfulness and reality
          of the information posted on the platform. To this end, You agree not to create, transmit
          or distribute through the Platform any content that:
        </p>
        <ul data-rte-list="default">
          <li>
            <p>
              Is illegal or would constitute, encourage or provide instructions for a criminal
              offence, or violate the rights of any other party, violate any national or
              international laws or in any other way cause harm or prejudice to another person;
            </p>
          </li>
          <li>
            <p>
              Is defamatory, violent, harassing, hateful, threatening, offensive, discriminatory,
              abusive, indecent, invasive of privacy, misleading, fraudulent or otherwise
              unacceptable;
            </p>
          </li>
          <li>
            <p>
              May infringe a trademark, trade secret, copyright or other intellectual property
              right;
            </p>
          </li>
          <li>
            <p>
              By which You impersonate another person or entity or by which your affiliation with a
              person or entity is incorrectly created;
            </p>
          </li>
          <li>
            <p>Is designed to trick or deceive other Users of the Nordensa Platform&nbsp;</p>
          </li>
        </ul>
        <p>
          We reserve the right to remove content that is reported by other Users as violating this
          paragraph or that are manifestly contrary to this paragraph, without assuming any general
          obligation to monitor the content. To report content pursuant to this paragraph, you may
          contact us at <a href="mailto:champions@nordensa.com">champions@nordensa.com</a>.
        </p>
        <p>
          <br />
          In order to become a Backer, and as part of entering into a partnership with Nordensa,
          Registered Users will need to agree to and sign the Nordensa JV Contract such in order to
          back a specific player the Registered User selected. The Registered User will have full
          access to the Nordensa JV contract before being taken to the signature page after which he
          will be redirected to Nordensa’s payment service provider in order to contribute the
          corresponding funds and obtain the corresponding share(s) in the possible Player Service
          Fee. In order to be able to sign the Nordensa JV Contract and become a Backer, Users need
          to provide accurate personal information.
          <br />
          Depending on the quantity of the contribution needed, several JV’s might be set up for the
          same player.
          <strong>
            <br />
            <br />
          </strong>
        </p>
        <p>
          <strong>8. INTELLECTUAL PROPERTY</strong>
        </p>
        <p>
          Nordensa is the owner and/or licensee of all the rights over the content of the Nordensa
          Platform, including but not limited to images, texts, trademarks, logo, web graphic
          elements, scripts and databases available therein. All rights, titles and interests in the
          Nordensa Platform including without limitation any intellectual property rights, as well
          as any know-how or ideas developed by Nordensa while providing the Services is and will
          remain the exclusive property of Nordensa or its licensors permanently.
        </p>
        <p>
          The trademarks, logo and slogans displayed on the Nordensa Platform, including the mark
          „Nordensa”, (collectively the “Trademarks“) are owned by Nordensa. Nothing on the Nordensa
          Platform should be construed as granting any license or right to use the Trademarks
          without written permission of Nordensa. Your misuse of the Trademarks, or any other
          content on the Platform, except as provided in these Terms and Conditions, is strictly
          prohibited.
        </p>
        <p></p>
        <p>
          <strong>
            9. LINKS TO OTHER WEBSITES
            <br />
            <br />
          </strong>
          Our Service may contain links to third-party web sites or services that are not owned or
          controlled by the Company.
        </p>
        <p>
          The Company has no control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You further acknowledge
          and agree that the Company shall not be responsible or liable, directly or indirectly, for
          any damage or loss caused or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods or services available on or through any such web sites
          or services.
        </p>
        <p>
          We strongly advise You to read the terms and conditions and privacy policies of any
          third-party web sites or services that You visit.
        </p>
        <p></p>
        <p>
          <strong>10. TERMINATION</strong>
        </p>
        <p>
          We may terminate or suspend Your access immediately, without prior notice or liability,
          for any reason whatsoever, including without limitation if You breach these Terms and
          Conditions.
        </p>
        <p>Upon termination, Your right to use the Service will cease immediately.</p>
        <p></p>
        <p>
          <strong>
            11. LIMITATION OF LIABILITY
            <br />
          </strong>
          <br />
          To the maximum extent permitted by applicable law, in no event shall the Company or its
          suppliers be liable for any special, incidental, indirect, or consequential damages
          whatsoever (including, but not limited to, damages for loss of profits, loss of data or
          other information, for business interruption, for personal injury, loss of privacy arising
          out of or in any way related to the use of or inability to use the Service, third-party
          software and/or third-party hardware used with the Service, or otherwise in connection
          with any provision of this Terms), even if the Company or any supplier has been advised of
          the possibility of such damages and even if the remedy fails of its essential purpose.
        </p>
        <p></p>
        <p>
          <strong>
            12. “AS IS” AND “AS AVAILABLE” DISCLAIMER
            <br />
          </strong>
          <br />
          The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects
          without warranty of any kind. To the maximum extent permitted under applicable law,
          Nordensa, on its own behalf and on behalf of its Affiliates and its and their respective
          licensors and service providers, expressly disclaims all warranties, whether express,
          implied, statutory or otherwise, with respect to the Service, including all implied
          warranties of merchantability, fitness for a particular purpose, title and
          non-infringement, and warranties that may arise out of course of dealing, course of
          performance, usage or trade practice. Without limitation to the foregoing, Nordensa
          provides no warranty or undertaking, and makes no representation of any kind that the
          Service will meet Your requirements, achieve any intended results, be compatible or work
          with any other software, applications, systems or services, operate without interruption,
          meet any performance or reliability standards or be error free or that any errors or
          defects can or will be corrected.
        </p>
        <p>
          Without limiting the foregoing, neither Nordensa nor any of the Nordensa's provider makes
          any representation or warranty of any kind, express or implied: (i) as to the operation or
          availability of the Service, or the information, content, and materials or products
          included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to
          the accuracy, reliability, or currency of any information or content provided through the
          Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on
          behalf of Nordensa are free of viruses, scripts, trojan horses, worms, malware, timebombs
          or other harmful components.
        </p>
        <p>
          We do not provide any investment or financial advice, nor are We an investment andor job
          placement company. Thus, any reference to “investments” displayed on the Website and in
          the Nordensa Platform is mentioned only for illustrative/metaphorical purposes. Nordensa
          is not an entity licensed by any relevant competent authority for the provision of any
          investment and or financial services. It is at a User’s sole discretion and judgement
          whether they want to partner with Nordensa and enter into a JV with regard to supporting
          any Player. We are not responsible for the use or interpretation of information available
          on the Website and on Nordensa Platform.
        </p>
        <p>
          The Service is available only to persons who are in full civil capacity according to their
          national regulations. You must understand and assume the risks associated with the use of
          the Service, and it is recommended to exercise a high level of prudence and responsibility
          in using the Service.
        </p>
        <p>
          For the moment, the Service is only targeting citizens of member states of the European
          Union (this may change in the future). Citizens of the United States and other US
          territories do not have access to this Service. If You are a European Union consumer, you
          will benefit from any mandatory provisions of the law of the country in which you are
          resident.
          <br />
          <br />
        </p>
        <p>
          <strong>13. GOVERNING LAW</strong>
        </p>
        <p>
          The laws of the Country (Romanian law), excluding its conflicts of law rules, shall govern
          this Terms and Your use of the Service. Your use of the Application may also be subject to
          other local, state, national, or international laws.
        </p>
        <p></p>
        <p>
          <strong>14. DISPUTES RESOLUTION</strong>
        </p>
        <p>
          If You have any concern or dispute about the Service, You agree to first try to resolve
          the dispute amicably, by contacting the Company.
        </p>
        <p></p>
        <p>
          <strong>15. SEVERABILITY AND WAIVER</strong>
        </p>
        <p>
          <strong>
            <em>Severability</em>
          </strong>
        </p>
        <p>
          If any provision of these Terms is held to be unenforceable or invalid, such provision
          will be changed and interpreted to accomplish the objectives of such provision to the
          greatest extent possible under applicable law and the remaining provisions will continue
          in full force and effect.
        </p>
        <p>
          <strong>
            <em>Waiver</em>
          </strong>
        </p>
        <p>
          Except as provided herein, the failure to exercise a right or to require performance of an
          obligation under this Terms shall not effect a party's ability to exercise such right or
          require such performance at any time thereafter nor shall be the waiver of a breach
          constitute a waiver of any subsequent breach.
          <br />
        </p>
        <p>
          <strong>
            <br />
            16. TRANSLATION INTERPRETATION
          </strong>
        </p>
        <p>
          These Terms and Conditions may have been translated if We have made them available to You
          on our Service. You agree that the original English text shall prevail in the case of a
          dispute.
        </p>
        <p>
          <br />
          <strong>
            <br />
            17. CHANGES TO THESE TERMS AND CONDITIONS
          </strong>
        </p>
        <p>
          We reserve the right, at Our sole discretion, to modify or replace these Terms at any
          time. If a revision is material, We will make reasonable efforts to provide at least 30
          days' notice prior to any new terms taking effect. What constitutes a material change will
          be determined at Our sole discretion.
        </p>
        <p>
          By continuing to access or use Our Service after those revisions become effective, You
          agree to be bound by the revised terms. If You do not agree to the new terms, in whole or
          in part, please stop using the Nordensa Platform and the Service.
        </p>
        <p data-rte-preserve-empty="true"></p>
        <p>
          <strong>Contact Us</strong>&nbsp;
        </p>
        <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
        <p>By email: champions@nordensa.com</p>
        <p></p>
      </div>
    </PageContent>
  )
}

export default TermsAndConditions
