import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import React from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Button from '@mui/material/Button'
import { DialogHeader } from 'components/DialogHeader'
import LoadingOverlay from 'components/LoadingOverlay'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { SharePlayerDialogProps } from './types'

import styles from './SharePlayerDialog.module.scss'

const SharePlayerDialog = ({
  isSharePlayerDialogOpen,
  setIsSharePlayerDialogOpen,
  isReferralLinkCreating,
  referralLink,
  setReferralLink,
}: SharePlayerDialogProps) => {
  const handleCloseModalFromButton = () => {
    setIsSharePlayerDialogOpen(false)
    setReferralLink('')
  }

  const handleOnClose = (event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
    handleCloseModalFromButton()
  }

  return (
    <Dialog
      open={isSharePlayerDialogOpen}
      onClose={handleOnClose}
      maxWidth="lg"
      disableEscapeKeyDown={true}
      PaperComponent={({ children }) => {
        return <Box className={styles.paperComponent}>{children}</Box>
      }}
    >
      <DialogContent className={styles.dialog}>
        {isReferralLinkCreating && <LoadingOverlay />}
        <DialogHeader title="Share player" handleOnClose={handleCloseModalFromButton} />
        <Box className={styles.content}>
          {isReferralLinkCreating && (
            <Typography variant="subtitle1" color="#fff">
              Generating the referral link. Please wait...
            </Typography>
          )}
          {referralLink && (
            <Box>
              <Typography marginBottom={3}>REFERRAL LINK</Typography>
              <Typography variant="subtitle1" color="#fff" fontWeight={600} marginBottom={3}>
                {referralLink}
              </Typography>
              <Button
                variant="contained"
                disableRipple={true}
                className={styles.copyButton}
                onClick={() => {
                  navigator.clipboard.writeText(referralLink)
                }}
                endIcon={<ContentCopyIcon />}
              >
                Copy link to clipboard
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SharePlayerDialog
