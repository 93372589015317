import validator from 'validator'

const isNumeric = (value: string) => {
  if (!validator.isNumeric(value)) {
    return 'Must contain only numbers'
  }

  return ''
}

export default isNumeric
