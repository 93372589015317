import validator from 'validator'

const isEmail = (value: string) => {
  if (!validator.isEmail(value)) {
    return 'A valid e-mail address is required'
  }

  return ''
}

export default isEmail
