export type ConsentType = {
  allowAllCookies: boolean
  rejectNonEssentialCookies: boolean
} | null

export const setConsent = (value: ConsentType) => {
  try {
    localStorage.setItem('consent', JSON.stringify(value))
  } catch (error) {
    console.log('Error: could not set consent', error)
  }
}

export const getConsent = (): ConsentType => {
  try {
    const consent = localStorage.getItem('consent')
    return consent ? JSON.parse(consent) : null
  } catch (error) {
    console.log('Error: could not determine consent', error)
    localStorage.removeItem('consent')
    return null
  }
}
