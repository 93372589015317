import { GenerateConfigParams } from './types'

const generateConfig = (params: GenerateConfigParams) => {
  const { authToken, contentType, refHeader } = params

  return {
    mode: 'cors',
    headers: {
      Authorization: authToken ? authToken : '',
      'Content-Type': contentType ? contentType : 'application/json',
      'X-Requested-With': refHeader && process.env.REACT_APP_IS_REFERRAL_ENABLED ? refHeader : '',
    },
  }
}

export default generateConfig
