import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import RequiredSign from 'components/Required'
import { SelectComponentProps } from './types'

const SelectComponent = ({
  label,
  helperText,
  required,
  disabled,
  value,
  onChange,
  children,
  fieldVariant = 'default',
}: SelectComponentProps) => {
  const helperTextColor = fieldVariant === 'default' ? '#a2a39b' : '#000000'
  const labelColor = fieldVariant === 'default' ? '#fafcf2' : '#FAFCF2'

  const variants = {
    default: {
      '&.MuiInputBase-root': {
        borderRadius: '10px',
        border: 'none',
        backgroundColor: '#122529',
        color: '#fafcf2',
        '&:hover': {
          boxShadow: '0px 0px 0px 4px rgba(252, 109, 75, 0.25)',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
        },
      },
      '&.MuiOutlinedInput-root': {
        fieldset: {
          border: '1px solid #65665E',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FC6D4B',
          borderRadius: '10px',
          boxShadow: '0px 0px 0px 4px rgba(252, 109, 75, 0.25)',
        },
      },
    },
    dialog: {
      '&.MuiInputBase-root': {
        borderRadius: '10px',
        border: '1px solid #fff',
        backgroundColor: '#FC6D4B',
        boxShadow: 'none',
        color: '#FAFCF2',
        '&:hover': {
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
        },
      },
      '&.MuiOutlinedInput-root': {
        fieldset: {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          borderRadius: '10px',
          border: 'none',
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
      },
    },
    login: {
      '&.MuiInputBase-root': {
        borderRadius: '10px',
        border: '2px solid #E4E5DB',
        backgroundColor: '#E4E5DB',
        boxShadow: 'none',
        color: '#000000',
        '&:hover': {
          border: '2px solid #65665E',
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
        '&:focus-within': {
          border: '2px solid #65665E',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
          border: 'none',
          backgroundColor: '#C1C2B9',
        },
      },
      '&.MuiOutlinedInput-root': {
        fieldset: {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          borderRadius: '10px',
          border: 'none',
          boxShadow: 'none',
        },
      },
      '& .MuiFormHelperText-root': {
        marginLeft: '0px',
        color: '#000000',
        '&.Mui-error': {
          marginLeft: '0px',
          color: '#000000',
        },
      },
    },
  }

  const labelSize: Record<'default' | 'dialog' | 'login', 'caption' | 'body1'> = {
    login: 'body1',
    default: 'caption',
    dialog: 'caption',
  }

  return (
    <FormControl fullWidth>
      <Typography
        variant={labelSize[fieldVariant] || 'caption'}
        style={{ marginBottom: '4px', color: labelColor }}
      >
        {label} {required && <RequiredSign variant={fieldVariant} />}
      </Typography>
      <Select
        disabled={disabled}
        size="small"
        value={value}
        onChange={onChange}
        sx={variants[fieldVariant]}
      >
        {children}
      </Select>
      <Typography variant="caption" mt="4px" color={helperTextColor}>
        {helperText}
      </Typography>
    </FormControl>
  )
}

export default SelectComponent
