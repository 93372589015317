const containsNumber = (value: string) => {
  const validationRule = new RegExp(/\d/)

  if (!validationRule.test(value)) {
    return 'Must contain at least one number'
  }

  return ''
}

export default containsNumber
