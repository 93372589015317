import AuthorizationError from 'network-layer/errors/AuthorizationError'
import BadRequestError from 'network-layer/errors/BadRequestError'
import ForbiddenError from 'network-layer/errors/ForbiddenError'
import RateLimiterError from 'network-layer/errors/RateLimiterError'
import VagueAPIError from 'network-layer/errors/VagueAPIError'

const customError = (responseStatus: number, errorBody: Record<string, unknown>) => {
  switch (responseStatus) {
    case 400:
      return new BadRequestError(responseStatus, errorBody)
    case 403:
      return new ForbiddenError(responseStatus, errorBody)
    case 401:
      return new AuthorizationError(responseStatus, errorBody)
    case 429:
      return new RateLimiterError(responseStatus, errorBody)

    default:
      // for when the API returns a status code that is not accounted for
      return new VagueAPIError(responseStatus, errorBody)
  }
}

export default customError
