import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CheckoutForm } from 'components/CheckoutForm'
import AppContext from 'contexts/App'
import React, { useContext } from 'react'
import { PaymentIntent } from 'types'

const PaymentDialogPaymentDetailsStep = ({
  paymentIntent,
  isPaymentElementReady,
  setIsPaymentElementReady,
  handleCloseModalFromButton,
}: {
  paymentIntent: PaymentIntent | null
  isPaymentElementReady: boolean
  setIsPaymentElementReady: React.Dispatch<React.SetStateAction<boolean>>
  handleCloseModalFromButton: () => void
}) => {
  const { stripeSecret } = useContext(AppContext)

  return (
    <>
      <Elements
        stripe={loadStripe(stripeSecret)}
        options={{
          // passing the client secret obtained from the server
          clientSecret: paymentIntent?.paymentIntent,
        }}
      >
        <CheckoutForm
          paymentIntent={paymentIntent!}
          isPaymentElementReady={isPaymentElementReady}
          setIsPaymentElementReady={setIsPaymentElementReady}
          handleCloseModalFromButton={handleCloseModalFromButton}
        />
      </Elements>
    </>
  )
}

export default PaymentDialogPaymentDetailsStep
