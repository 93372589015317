interface ParseError extends Error {
  body?: Error
}

// eslint-disable-next-line no-redeclare
class ParseError extends Error {
  constructor(error: Error) {
    super('Parse error!')

    this.name = 'ParseError'
    this.body = error

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ParseError)
    }
  }
}

export default ParseError
