import GET__players from 'network-layer/endpoints/GET__players'
import { GetPlayersParams } from 'services/types'
import { APIPlayer, Player, PlayerAvailabilityStatus } from 'types'

const getPlayers = async (params: GetPlayersParams) => {
  const apiPlayers: APIPlayer[] = await GET__players(params)

  const players: Player[] = apiPlayers.map((player) => {
    let availabilityStatus: PlayerAvailabilityStatus = ''
    let campaignLabel = ''

    const {
      availability,
      shares: { totalShares, availableShares },
    } = player

    const fundedPercent = Math.floor(((totalShares - availableShares) * 100) / totalShares)

    if (!availability) {
      availabilityStatus = 'not available'
    } else {
      const start = new Date(availability.startDate).getTime()
      const end = new Date(availability.endDate).getTime()
      const now = new Date().getTime()

      if (start > now) {
        availabilityStatus = 'coming soon'
        campaignLabel = 'Coming soon'
      } else if (now > end || availableShares === 0) {
        availabilityStatus = 'expired'
      } else if (start < now && now < end && availableShares > 0) {
        availabilityStatus = 'ongoing'
        if (availability.endsIn) {
          const {
            endsIn: { days, hours, minutes },
          } = availability
          if (days > 0) {
            campaignLabel = `${days} days`
          } else if (hours > 0) {
            campaignLabel = `${hours} hours`
          } else if (minutes > 0) {
            campaignLabel = `${minutes} minutes`
          } else {
            campaignLabel = `N/A`
          }
        }
      }
    }

    return {
      ...player,
      aux: {
        availabilityStatus,
        campaignLabel,
        fundedPercent,
      },
    }
  })

  return players
}

export default getPlayers
