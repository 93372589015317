import successBody from 'network-layer/util/successBody'

const POST = async (
  endpoint: string,
  config: Record<string, unknown>,
  body: Record<string, unknown>,
) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    ...config,
    method: 'POST',
    body: JSON.stringify(body),
  })

  return await successBody(response)
}

export default POST
