const containsUppercase = (value: string) => {
  const validationRule = new RegExp(/[A-Z]/)

  if (!validationRule.test(value)) {
    return 'Must contain at least one uppercase letter'
  }

  return ''
}

export default containsUppercase
