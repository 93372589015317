import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import React from 'react'

const PaymentDialogConfirmPurchaseStep = ({
  selectedShares,
  pricePerShare,
  processingFee,
  imageUrl,
  isContractAccepted,
  setIsContractAccepted,
  handleCreatePaymentIntent,
  isPaymentIntentCreating,
  hasPaymentIntentError,
}: {
  selectedShares: number
  pricePerShare: number
  processingFee: number
  imageUrl: string
  isContractAccepted: boolean
  setIsContractAccepted: React.Dispatch<React.SetStateAction<boolean>>
  handleCreatePaymentIntent: () => void
  isPaymentIntentCreating: boolean
  hasPaymentIntentError: boolean
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
        }}
      >
        {/* LEFT COLUMN */}
        <div
          style={{
            width: '250px',
          }}
        >
          <img src={imageUrl} alt="player" width="253" style={{ borderRadius: '24px' }} />
        </div>

        {/* RIGHT COLUMN */}
        <div
          style={{
            flexGrow: 2,
            padding: '0px 0px 0px 48px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
            }}
          >
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 900,
                color: '#FAFCF2',
              }}
            >
              Shares:
            </Typography>
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 900,
                color: '#FAFCF2',
              }}
            >
              {selectedShares}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
            }}
          >
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 900,
                color: '#FAFCF2',
              }}
            >
              Price / Share:
            </Typography>
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 900,
                color: '#FAFCF2',
              }}
            >
              {pricePerShare}€
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
            }}
          >
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 900,
                color: '#FAFCF2',
              }}
            >
              Processing Fee:
            </Typography>
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 900,
                color: '#FAFCF2',
              }}
            >
              {processingFee}€
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
            }}
          >
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 900,
                color: '#3C484C',
              }}
            >
              Total Price:
            </Typography>
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 900,
                color: '#3C484C',
              }}
            >
              {selectedShares * pricePerShare + processingFee}€
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flexStart',
              alignItems: 'center',
            }}
          >
            <Switch
              disabled={isPaymentIntentCreating}
              checked={isContractAccepted}
              onChange={() => {
                setIsContractAccepted(!isContractAccepted)
              }}
            />
            <Link
              target="_blank"
              href={`${process.env.REACT_APP_API_URL}/img/backer-sample-contract.pdf`}
            >
              I have read and accept the contract
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          marginBottom: '4px',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {hasPaymentIntentError && (
          <Typography
            variant="body1"
            style={{ fontWeight: 500, color: '#fff', marginRight: '16px' }}
          >
            An error was encountered while preparing the payment. Please try again in a few minutes.
          </Typography>
        )}
        <Button
          disabled={isPaymentIntentCreating || !isContractAccepted}
          variant="contained"
          disableRipple={true}
          size="large"
          sx={{
            padding: '16px',
            borderRadius: '10px',
            backgroundColor: '#3C484C',
            boxShadow: 'none',
            color: '#F2F2F3',
          }}
          onClick={handleCreatePaymentIntent}
          endIcon={<ArrowForwardIcon />}
        >
          <Typography>Payment Details</Typography>
        </Button>
      </div>
    </>
  )
}

export default PaymentDialogConfirmPurchaseStep
