import containsLowercase from './containsLowercase'
import containsNumber from './containsNumber'
import containsSpecialCharacter from './containsSpecialCharacter'
import containsUppercase from './containsUppercase'
import hasMaxLength from './hasMaxLength'
import hasMinLength from './hasMinLength'

const isPassword = (value: string) => {
  return (
    containsLowercase(value) ||
    containsUppercase(value) ||
    containsNumber(value) ||
    containsSpecialCharacter(value) ||
    hasMinLength(value, 8) ||
    hasMaxLength(value, 72)
  )
}

export default isPassword
