interface AuthorizationError extends Error {
  body?: Record<string, any>
  responseStatus: number
}

// eslint-disable-next-line no-redeclare
class AuthorizationError extends Error {
  constructor(responseStatus: number, errorBody: Record<string, any>) {
    super('Could not authorize the request!')

    this.name = 'AuthorizationError'
    this.body = errorBody
    this.responseStatus = responseStatus

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthorizationError)
    }
  }
}

export default AuthorizationError
