import generateConfig from 'network-layer/config/generateConfig'
import PUT from 'network-layer/verbs/PUT'
import { SavePlayerParams } from 'services/types'

const PUT__admin_players_PLAYERID = async (params: SavePlayerParams) => {
  const { playerId, authToken } = params

  return await PUT(`/admin/players/${playerId}`, generateConfig({ authToken }), params)
}

export default PUT__admin_players_PLAYERID
