import generateConfig from 'network-layer/config/generateConfig'
import POST from 'network-layer/verbs/POST'
import { RegisterParams } from 'services/types'

const POST__register = async (params: RegisterParams) => {
  const { refHeader } = params
  return await POST('/register', generateConfig({ refHeader }), params)
}

export default POST__register
