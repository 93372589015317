import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingOverlay = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        zIndex: 1000,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </div>
  )
}

export default LoadingOverlay
