import { uuidv4 } from '@firebase/util'
import ArticleIcon from '@mui/icons-material/Article'
import CloseIcon from '@mui/icons-material/Close'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import ShareIcon from '@mui/icons-material/Share'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { default as classNames, default as cn } from 'classnames'
import { DetailsPill } from 'components/DetailsPill'
import LoadingScreen from 'components/LoadingScreen'
import PaymentDialog from 'components/PaymentDialog'
import PlayerThumbnail from 'components/PlayerThumbnail'
import { SharePlayerDialog } from 'components/SharePlayerDialog'
import AppContext from 'contexts/App'
import { useContext, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Carousel from 'react-material-ui-carousel'
import { Link, useMatch, useNavigate, useParams } from 'react-router-dom'
import getAge from 'util/getAge'
import './index.css'
import styles from './PlayerDetails.module.scss'

const PlayerDetails = () => {
  const [isArticleVisible, setIsArticleVisible] = useState<boolean>(false)
  const [isFundingModalOpen, setIsFundingModalOpen] = useState<boolean>(false)
  const [referralLink, setReferralLink] = useState<string>('')
  const [isReferralLinkCreating, setIsReferralLinkCreating] = useState<boolean>(false)
  const [isSharePlayerDialogOpen, setIsSharePlayerDialogOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { players, appIsInitialized, news } = useContext(AppContext)
  const match = useMatch('/player/:playerId')
  const { id: playerId } = useParams()
  const handleFundPlayer = () => {
    setIsFundingModalOpen(true)
  }

  if (!appIsInitialized) {
    return <LoadingScreen />
  }

  const selectedPlayer = players.filter(
    (player) => Number(match?.params.playerId) === player.playerId,
  )[0]

  const {
    aux: { availabilityStatus },
  } = selectedPlayer

  const playerNews = news.filter((newsItem) => newsItem.playerId === parseInt(playerId || '', 10))

  if (!selectedPlayer) {
    return (
      <>
        <div className="contentWrapper">
          <div className="content">
            <Box
              style={{
                padding: 50,
              }}
            >
              <Typography variant="h5">Player ID not found.</Typography>
            </Box>
          </div>
        </div>
      </>
    )
  }

  const {
    firstName,
    lastName,
    passportCountry,
    position,
    birthDate,
    foot,
    height,
    similar,
    role,
    secondaryPosition,
    footage,
    quotes,
    stats,
  } = selectedPlayer

  let selectedQuote = null

  if (quotes) {
    selectedQuote = quotes[0]
  }

  const age = getAge(birthDate).toString()

  const toggleArticle = () => {
    setIsArticleVisible(!isArticleVisible)
  }

  const generareReferralLink = () => {
    setIsReferralLinkCreating(true)
    setIsSharePlayerDialogOpen(true)
    setTimeout(() => {
      setReferralLink('https://t0lsj.app.link/NYSYxC92aEb')
      setIsReferralLinkCreating(false)
    }, 1200)
  }

  return (
    <>
      <div className="contentWrapper">
        <div className="content">
          <div className="leftColumn">
            <PlayerThumbnail
              player={selectedPlayer}
              hasPlayerName={true}
              hasPlayerDetails={true}
              hasPlayerFundButton={true}
              hasCampaignExpiredButton={true}
              hasComingSoonButton={true}
              borderRadiusValue={10}
              variant="light"
              handleFundPlayer={handleFundPlayer}
            />
            {stats && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '24px 0px 0px',
                  padding: '16px 24px',
                  backgroundColor: '#243135',
                  borderRadius: '20px',
                  alignItems: 'center',
                }}
              >
                {stats.map((stat) => {
                  const { label, value } = stat

                  return (
                    <Box
                      key={label}
                      style={{
                        width: '100%',
                        height: '40px',
                        marginBottom: '8px',
                        color: '#7B989E',
                      }}
                    >
                      <Typography variant="subtitle2">{label}</Typography>
                      <LinearProgress
                        variant="determinate"
                        value={Number(value)}
                        sx={{
                          backgroundColor: '#466d76',
                          height: '16px',
                          borderRadius: '20px',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: '#DAE1E3',
                            borderRadius: '20px',
                          },
                        }}
                      />
                    </Box>
                  )
                })}
              </div>
            )}
            {availabilityStatus === 'ongoing' && process.env.REACT_APP_IS_REFERRAL_ENABLED && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '24px 0px 0px',
                  padding: '16px 24px',
                  backgroundColor: '#243135',
                  borderRadius: '20px',
                }}
              >
                <Box marginBottom={2}>
                  <Typography variant="subtitle2" color="#fff">
                    Share this athlete with your friends. For each new backer who buys at least 1
                    share, you'll get 1 FREE share.
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  startIcon={<ShareIcon />}
                  fullWidth
                  onClick={generareReferralLink}
                  style={{
                    borderRadius: '10px',
                    marginBottom: '12px',
                  }}
                >
                  Share Player
                </Button>
              </div>
            )}
          </div>
          <div className="rightColumn">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '32px',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography variant="h4" style={{ color: '#fafcf2' }}>
                  {firstName} {lastName}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    padding: '0px',
                    height: '35px',
                    width: '35px',
                    minWidth: '35px',
                    borderRadius: '10px',
                    backgroundColor: '#3C484C',
                    color: '#F2F2F3',
                  }}
                  size="small"
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '24px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  marginRight: '16px',
                }}
              >
                <FormatQuoteIcon
                  style={{
                    fontSize: 60,
                    color: '#c1c2b9',
                  }}
                />
              </div>
              {selectedQuote && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div>
                    <Typography variant="body1" style={{ color: '#fafcf2' }}>
                      {selectedQuote.quote}
                    </Typography>
                  </div>
                  <div
                    style={{
                      marginTop: '16px',
                    }}
                  >
                    <Typography variant="body2" style={{ color: '#6D8790' }}>
                      - {selectedQuote.scout}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '4px',
                justifyContent: 'space-between',
              }}
            >
              <DetailsPill label="Country" value={passportCountry} bgColor="#3c484c" />
              <DetailsPill label="Position" value={position} bgColor="#3c484c" margin="0px 4px" />
              <DetailsPill label="Age" value={age} bgColor="#3c484c" />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '4px',
              }}
            >
              <DetailsPill label="Foot" bgColor="#122529" value={foot} />
              <DetailsPill
                label="Height"
                bgColor="#122529"
                value={height.toString()}
                margin="0px 4px"
              />
              <DetailsPill label="Similar Player" bgColor="#122529" value={similar} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '24px',
              }}
            >
              <DetailsPill label="Role" bgColor="#122529" value={role} margin="0px 4px 0px 0px" />
              <DetailsPill label="Second Position" bgColor="#122529" value={secondaryPosition} />
            </div>
            {playerNews.length ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#fff',
                  marginBottom: '24px',
                }}
              >
                <Carousel
                  className={styles.carousel}
                  indicatorIconButtonProps={{
                    style: {
                      marginTop: 16,
                      color: '#fff',
                      zIndex: 1000,
                    },
                  }}
                  activeIndicatorIconButtonProps={{
                    style: {
                      color: '#fc6d4b',
                    },
                  }}
                  autoPlay={false}
                  interval={5000}
                  stopAutoPlayOnHover={true}
                  navButtonsWrapperProps={{
                    style: {
                      bottom: 'unset',
                      top: '-24px',
                    },
                  }}
                >
                  {playerNews.map((newsItem, i) => {
                    return (
                      <div
                        key={uuidv4()}
                        style={{
                          position: 'relative',
                          borderRadius: 20,
                          height: 180,
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            background: `linear-gradient(
                          90deg,
                          rgba(4,15,17,0.7) 50%,
                          rgba(4,15,17,0.7) 50%
                        ), url(${newsItem.image}) center no-repeat`,
                            borderRadius: 20,
                            height: '100%',
                            width: '100%',
                          }}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                          }}
                        >
                          <div className={cn(styles.carouselText, styles.carouselText_name)}>
                            <Typography variant="h4">{newsItem.title}</Typography>
                          </div>
                          <div className={cn(styles.carouselText, styles.carouselText_description)}>
                            <Typography variant="body1">{newsItem.heading}</Typography>
                          </div>
                          <div className={styles.carouselAction}>
                            {newsItem.content && (
                              <Link to={`/news/${newsItem.newsId}`} target="_blank">
                                <Button variant="contained">Read More</Button>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Carousel>
              </div>
            ) : null}
            {selectedQuote?.text && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '24px',
                }}
              >
                <div
                  className={classNames(
                    'articleContainer',
                    isArticleVisible && 'articleContainerExpanded',
                  )}
                >
                  <div className={classNames('overlay', isArticleVisible && 'overlayHidden')} />
                  <div
                    style={{
                      position: 'absolute',
                      margin: 0,
                      top: 0,
                      left: 0,
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      height: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        padding: '16px',
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<ArticleIcon />}
                        onClick={toggleArticle}
                        sx={{
                          borderRadius: '10px',
                        }}
                      >
                        {isArticleVisible ? 'Close full report' : 'Read full report'}
                      </Button>
                    </div>
                  </div>
                  <span style={{ color: '#fafcf2' }}>
                    <ReactMarkdown>{selectedQuote?.text || ''}</ReactMarkdown>
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {footage &&
                footage.map(({ id }) => {
                  return (
                    <div
                      key={id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        borderRadius: 8,
                        overflow: 'hidden',
                      }}
                    >
                      <iframe
                        title="title"
                        src={`https://player.vimeo.com/video/${id}`}
                        width="670"
                        height="375"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        style={{ borderRadius: 8 }}
                      ></iframe>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>

      {availabilityStatus === 'ongoing' && (
        <PaymentDialog
          isFundingModalOpen={isFundingModalOpen}
          setIsFundingModalOpen={setIsFundingModalOpen}
          player={selectedPlayer}
        />
      )}

      {isSharePlayerDialogOpen && (
        <SharePlayerDialog
          isSharePlayerDialogOpen={isSharePlayerDialogOpen}
          setIsSharePlayerDialogOpen={setIsSharePlayerDialogOpen}
          isReferralLinkCreating={isReferralLinkCreating}
          referralLink={referralLink}
          setReferralLink={setReferralLink}
        />
      )}
    </>
  )
}

export default PlayerDetails
