import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

type LogEventParams = {
  eventName: string
  eventData?: Record<string, any>
}

const firebaseConfig: Record<string, Record<string, string>> = {
  production: {
    apiKey: 'AIzaSyA8-_s1vJm3gXP4E0ML0V2eQIeJnvvFB5g',
    authDomain: 'nordensa-cff37.firebaseapp.com',
    projectId: 'nordensa-cff37',
    storageBucket: 'nordensa-cff37.appspot.com',
    messagingSenderId: '305905817016',
    appId: '1:305905817016:web:25532469009f851caf6b4d',
    measurementId: 'G-2LH8ZTRNDV',
  },
  development: {
    apiKey: 'AIzaSyA8-_s1vJm3gXP4E0ML0V2eQIeJnvvFB5g',
    authDomain: 'nordensa-cff37.firebaseapp.com',
    projectId: 'nordensa-cff37',
    storageBucket: 'nordensa-cff37.appspot.com',
    messagingSenderId: '305905817016',
    appId: '1:305905817016:web:40fe170d54961c42af6b4d',
    measurementId: 'G-LDJVD0VWVM',
  },
  local: {
    apiKey: 'AIzaSyA8-_s1vJm3gXP4E0ML0V2eQIeJnvvFB5g',
    authDomain: 'nordensa-cff37.firebaseapp.com',
    projectId: 'nordensa-cff37',
    storageBucket: 'nordensa-cff37.appspot.com',
    messagingSenderId: '305905817016',
    appId: '1:305905817016:web:0dbc7ceb7fbf143aaf6b4d',
    measurementId: 'G-KQKFQLJYNZ',
  },
}

let analytics: Analytics

const environment = process.env.REACT_APP_ENVIRONMENT

export const initialize = () => {
  if (analytics || !environment) {
    return
  }

  const app = initializeApp(firebaseConfig[environment])
  analytics = getAnalytics(app)
}

export const log = (params: LogEventParams) => {
  const { eventName, eventData } = params

  if (analytics) {
    logEvent(analytics, eventName, eventData)
    if (environment === 'local') {
      console.log('logEvent - eventName:', eventName, 'eventData:', eventData)
    }
  } else {
    console.log('firebase not initialized')
  }
}

const firebase = {
  initialize,
  log,
}

export default firebase
