import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import styles from './PageHeader.module.scss'
import { PageHeaderProps } from './types'

const PageHeader = ({ children }: PageHeaderProps) => {
  const navigate = useNavigate()
  return (
    <div className={styles.pageHeader}>
      {children}
      <div>
        <Button
          variant="contained"
          className={styles.closeButton}
          size="small"
          onClick={() => {
            navigate('/')
          }}
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
  )
}

export default PageHeader
