import Typography from '@mui/material/Typography'
import cn from 'classnames'
import styles from './DetailsPill.module.scss'
import { DetailsPillProps } from './types'

const DetailsPill = ({ label, value, bgColor = '#C4D3D6', margin = '0px' }: DetailsPillProps) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundColor: bgColor,
        margin: margin,
      }}
    >
      <Typography variant="caption" className={cn(styles.text, styles.text_label)}>
        {label}
      </Typography>
      <Typography variant="body1" className={cn(styles.text, styles.text_value)}>
        {value || 'N/A'}
      </Typography>
    </div>
  )
}

export default DetailsPill
