import generateConfig from 'network-layer/config/generateConfig'
import POST from 'network-layer/verbs/POST'
import { CreateIntentParams } from 'services/types'

const POST__payment_createIntent = async (params: CreateIntentParams) => {
  const { authToken, ...rest } = params
  return await POST('/payment/create-intent', generateConfig({ authToken }), { ...rest })
}

export default POST__payment_createIntent
