import Box from '@mui/material/Box'
import background from 'images/background.jpg'
import styles from './AppBackground.module.scss'

const AppBackground = () => {
  return (
    <Box
      style={{
        background: `#040F11 url(${background}) center -60px no-repeat fixed`,
      }}
      className={styles.background}
    />
  )
}

export default AppBackground
