import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { AuthenticationSection } from 'components/AuthenticationDialog'
import AppContext from 'contexts/App'
import React, { useContext } from 'react'
import { ForgotPasswordSection } from './components/ForgotPasswordSection'
import { LoginSection } from './components/LoginSection'
import { SignupSection } from './components/SignupSection'
import { SuccessSection } from './components/SuccessSection'

const PaymentDialogAuthenticationStep = ({
  setCurrentStep,
  section,
  setSection,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  section: AuthenticationSection
  setSection: React.Dispatch<React.SetStateAction<AuthenticationSection>>
}) => {
  const {
    user,
    setUser,
    setAuthToken,
    setPlayers,
    setStripeSecret,
    setIsAuthenticated,
    isAuthenticated,
    setRewards,
  } = useContext(AppContext)

  const title: Record<AuthenticationSection, string> = {
    login: 'Login',
    signup: 'Sign up',
    forgot_password: 'Forgot password',
    success_login: 'Success',
    success_signup: 'Success',
    success_forgot_password: 'Success',
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '450px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            marginRight: '32px',
          }}
        >
          <Typography variant="body1">
            In order to be able to support a player you need to be logged in to your Nordensa
            account. Please use the form to log in.
          </Typography>
          <Typography variant="body1" mt={6}>
            If you are a new visitor you can sign up for a new account using the Sign up form.
          </Typography>
          <Typography variant="body1" mt={6}>
            If you already have a Nordensa account but can't remember your password, you can use the
            Forgot password form to recover your credentials.
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            marginLeft: '32px',
          }}
        >
          <Typography variant="h5" fontWeight={700} mb={3}>
            {title[section]}
          </Typography>
          {/* Login section */}
          {section === 'login' && (
            <LoginSection
              setAuthToken={setAuthToken}
              setUser={setUser}
              setPlayers={setPlayers}
              setStripeSecret={setStripeSecret}
              setIsAuthenticated={setIsAuthenticated}
              setSection={setSection}
              setRewards={setRewards}
            />
          )}

          {/* Signup section */}
          {section === 'signup' && (
            <SignupSection
              setAuthToken={setAuthToken}
              setUser={setUser}
              setPlayers={setPlayers}
              setStripeSecret={setStripeSecret}
              setIsAuthenticated={setIsAuthenticated}
              setSection={setSection}
              setRewards={setRewards}
            />
          )}

          {/* Forgot Password section */}
          {section === 'forgot_password' && (
            <ForgotPasswordSection
              setAuthToken={setAuthToken}
              setUser={setUser}
              setPlayers={setPlayers}
              setStripeSecret={setStripeSecret}
              setIsAuthenticated={setIsAuthenticated}
              setSection={setSection}
            />
          )}

          {(section === 'success_login' ||
            section === 'success_signup' ||
            section === 'success_forgot_password') && <SuccessSection section={section} />}

          {/* Footer */}
          {!section.includes('success') && (
            <Box
              style={{
                padding: '16px 0 0',
                display: 'flex',
                flexDirection: 'column',
                height: '48px',
                justifyContent: 'space-around',
                alignItems: 'flex-start',
                marginBottom: '16px',
              }}
            >
              {section !== 'login' && (
                <Typography variant="body2" align="center" color="#fff">
                  Already have an account?{' '}
                  <Link
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault()
                      setSection('login')
                    }}
                    fontWeight={700}
                    underline="hover"
                  >
                    Login
                  </Link>
                </Typography>
              )}
              {section !== 'signup' && (
                <Typography variant="body2" align="center" color="#fff">
                  New to Nordensa?{' '}
                  <Link
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault()
                      setSection('signup')
                    }}
                    fontWeight={700}
                    underline="hover"
                  >
                    Sign up
                  </Link>
                </Typography>
              )}
              {section !== 'forgot_password' && (
                <Typography variant="body2" align="center" color="#fff">
                  Can't remember credentials?{' '}
                  <Link
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault()
                      setSection('forgot_password')
                    }}
                    fontWeight={700}
                    underline="hover"
                  >
                    Forgot password
                  </Link>
                </Typography>
              )}
            </Box>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '4px',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          disabled={!isAuthenticated}
          variant="contained"
          disableRipple={true}
          size="large"
          sx={{
            padding: '16px',
            borderRadius: '10px',
            backgroundColor: '#3C484C',
            boxShadow: 'none',
            color: '#F2F2F3',
          }}
          onClick={() => {
            setCurrentStep(user?.profileCompleted ? 'Confirm Purchase' : 'Complete Information')
          }}
          endIcon={<ArrowForwardIcon />}
        >
          <Typography>
            {user?.profileCompleted ? 'Confirm Purchase' : 'Complete Information'}
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default PaymentDialogAuthenticationStep
