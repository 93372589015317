import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import RemoveIcon from '@mui/icons-material/Remove'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import cn from 'classnames'
import ReactMarkdown from 'react-markdown'
import './index.css'
import { PaymentDialogSupportStepProps } from './types'

const PaymentDialogSupportStep = ({
  availableShares,
  fundedPercent,
  remaininginCampaignTime,
  chanceText,
  rewardsText,
  budgetSplit,
  selectedShares,
  setSelectedShares,
  selectedSharesTotalPrice,
  isAuthenticated,
  profileCompleted,
  setCurrentStep,
}: PaymentDialogSupportStepProps) => {
  return (
    <>
      {/* FUNDING PROGRESS ROW */}
      <div className={cn('supportStepRow', 'supportStepProgressRow')}>
        <div className="progressRowWrapper">
          <div className="supportStepProgressBarText">
            <Typography variant="body1">Funded: {Math.floor(fundedPercent)}%</Typography>
            <Typography variant="body1">{availableShares} shares left</Typography>
          </div>

          <Box sx={{ width: '100%', flexDirection: 'row' }}>
            <LinearProgress
              variant="determinate"
              value={fundedPercent}
              sx={{
                backgroundColor: '#FAFCF2',
                height: '12px',
                borderRadius: '20px',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#3c484c',
                },
              }}
            />
          </Box>

          <div className="supportStepRemainingCampaignTime">
            <Typography variant="body1">{remaininginCampaignTime}</Typography>
          </div>
        </div>
      </div>

      {/* DETAILS ROW */}
      <div className={cn('supportStepRow', 'supportStepDetailsRow')}>
        <div className={cn('supportStepDetails', 'supportStepDetailsMedium')}>
          <Typography style={{ marginBottom: '32px' }}>CHANCE</Typography>
          <ReactMarkdown>{chanceText}</ReactMarkdown>
        </div>
        <div className={cn('supportStepDetails', 'supportStepDetailsLarge')}>
          <Typography style={{ marginBottom: '32px' }}>REWARDS</Typography>
          <ReactMarkdown>{rewardsText}</ReactMarkdown>
        </div>
        <div className={cn('supportStepDetails', 'supportStepDetailsMedium')}>
          <Typography style={{ marginBottom: '32px' }}>FUNDS BREAKDOWN</Typography>
          {budgetSplit?.map((budget) => {
            const { label, value, type } = budget

            return (
              <Typography key={label}>
                {label} - {value}
                {type === 'absolute' ? 'EUR' : '%'}
              </Typography>
            )
          })}
        </div>
      </div>

      {/* SHARES SELECTOR ROW */}
      <div className={cn('supportStepRow', 'supportStepSharesRow')}>
        <div className="supportStepCounter">
          <Button
            variant="contained"
            disableRipple={true}
            disabled={selectedShares === 1}
            sx={{
              padding: '0px',
              height: '45px',
              width: '45px',
              minWidth: '45px',
              borderRadius: '10px',
              backgroundColor: '#FAFCF2',
              color: '#3C484C',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3C484C',
                color: '#FAFCF2',
                boxShadow: 'none',
              },
              '&:active': {
                backgroundColor: '#3C484C',
                color: '#FAFCF2',
                boxShadow: 'none',
              },
            }}
            onClick={() => {
              if (selectedShares > 1) {
                setSelectedShares(selectedShares - 1)
              }
            }}
          >
            <RemoveIcon />
          </Button>
          <Typography
            variant="h2"
            style={{ fontWeight: 900, color: '#FAFCF2', userSelect: 'none' }}
          >
            {selectedShares}
          </Typography>
          <Button
            variant="contained"
            disableRipple={true}
            disabled={selectedShares === availableShares}
            sx={{
              padding: '0px',
              height: '45px',
              width: '45px',
              minWidth: '45px',
              borderRadius: '10px',
              backgroundColor: '#FAFCF2',
              color: '#3C484C',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3C484C',
                color: '#FAFCF2',
                boxShadow: 'none',
              },
              '&:active': {
                backgroundColor: '#3C484C',
                color: '#FAFCF2',
                boxShadow: 'none',
              },
            }}
            onClick={() => {
              if (selectedShares < availableShares) {
                setSelectedShares(selectedShares + 1)
              }
            }}
          >
            <AddIcon />
          </Button>
        </div>
        <div className="supportStepTotalPrice">
          <div>
            <Typography
              variant="h4"
              style={{ fontWeight: 900, color: '#FAFCF2', userSelect: 'none' }}
            >
              Total Price:
            </Typography>
          </div>
          <div>
            <Typography
              variant="h4"
              style={{ fontWeight: 900, color: '#FAFCF2', userSelect: 'none' }}
            >
              {selectedSharesTotalPrice}€
            </Typography>
          </div>
        </div>
      </div>

      {/* ACTIONS ROW */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          marginBottom: '4px',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          disableRipple={true}
          size="large"
          sx={{
            padding: '16px',
            borderRadius: '10px',
            backgroundColor: '#3C484C',
            boxShadow: 'none',
            color: '#F2F2F3',
          }}
          onClick={() => {
            if (!isAuthenticated) {
              setCurrentStep('Authentication')
            } else if (!profileCompleted) {
              setCurrentStep('Complete Information')
            } else {
              setCurrentStep('Confirm Purchase')
            }
          }}
          endIcon={<ArrowForwardIcon />}
        >
          <Typography>
            {!isAuthenticated
              ? 'Login'
              : !profileCompleted
                ? 'Complete Information'
                : 'Select Shares'}
          </Typography>
        </Button>
      </div>
    </>
  )
}

export default PaymentDialogSupportStep
