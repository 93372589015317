import generateConfig from 'network-layer/config/generateConfig'
import POST from 'network-layer/verbs/POST'
import { LoginParams } from 'services/types'

export default async function POST__login(params: LoginParams) {
  const { email, password, refHeader, auth_provider, credential } = params

  const login = await POST('/login', generateConfig({ refHeader }), {
    email,
    password,
    auth_provider,
    credential,
  })
  return login
}
