import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import RequiredSign from 'components/Required'
import { DatePickerComponentProps } from './types'

const DatePickerComponent = ({
  disabled,
  label,
  required,
  helperText,
  value,
  onChange,
  fieldVariant = 'default',
}: DatePickerComponentProps) => {
  const helperTextColor = fieldVariant === 'default' ? '#a2a39b' : '#000000'
  const labelColor = fieldVariant === 'default' ? '#fafcf2' : '#FAFCF2'

  const variants = {
    default: {
      '.MuiDateCalendar-root': {
        backgroundColor: '#000',
      },
      svg: {
        color: '#fafcf2',
      },
      '& .MuiInputBase-root': {
        borderRadius: '10px',
        border: 'none',
        backgroundColor: '#122529',
        color: '#fafcf2',
        '&:hover': {
          boxShadow: '0px 0px 0px 4px rgba(252, 109, 75, 0.25)',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
        },
      },
      '& .MuiInputAdornment-root': {
        color: 'pink',
      },
      '& .MuiOutlinedInput-root': {
        fieldset: {
          border: '1px solid #65665E',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FC6D4B',
          borderRadius: '10px',
          boxShadow: '0px 0px 0px 4px rgba(252, 109, 75, 0.25)',
        },
      },
    },
    dialog: {
      '& .MuiInputBase-root': {
        borderRadius: '10px',
        border: '1px solid #fff',
        backgroundColor: '#FC6D4B',
        boxShadow: 'none',
        color: '#000000 !important',
        '&:hover': {
          border: '2px solid #65665E !important',
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
        },
      },
      '& .MuiOutlinedInput-root': {
        fieldset: {
          border: 'none',
        },
        '& .Mui-focused fieldset': {
          borderRadius: '10px',
          border: 'none',
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
      },
    },
    login: {
      '& .MuiInputBase-root': {
        borderRadius: '10px',
        border: '2px solid #E4E5DB',
        backgroundColor: '#E4E5DB',
        boxShadow: 'none',
        color: '#000000',
        '&:hover': {
          border: '2px solid #65665E',
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
        '&:focus-within': {
          border: '2px solid #65665E',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
          border: 'none',
          backgroundColor: '#C1C2B9',
        },
      },
      '& .MuiOutlinedInput-root': {
        fieldset: {
          border: 'none',
        },
        '& .Mui-focused fieldset': {
          borderRadius: '10px',
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
  }

  const labelSize: Record<'default' | 'dialog' | 'login', 'caption' | 'body1'> = {
    login: 'body1',
    default: 'caption',
    dialog: 'caption',
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl
        fullWidth
        sx={{
          '&.MuiFormControl-root > p': {
            marginLeft: '0px',
            // marginTop: '4px',
          },
        }}
      >
        <Typography
          variant={labelSize[fieldVariant] || 'caption'}
          style={{ marginBottom: '4px', color: labelColor }}
        >
          {label} {required && <RequiredSign variant={fieldVariant} />}
        </Typography>
        <DatePicker
          disabled={disabled}
          format="YYYY/MM/DD"
          value={value}
          onChange={onChange}
          slotProps={{
            textField: {
              size: 'small',
              error: false,
            },
          }}
          sx={variants[fieldVariant]}
        />
        <Typography variant="caption" mt="4px" color={helperTextColor}>
          {helperText}
        </Typography>
      </FormControl>
    </LocalizationProvider>
  )
}

export default DatePickerComponent
