class UnknownContentTypeHeaderError extends Error {
  constructor(contentTypeHeader: string | null) {
    super("UnknownContentType error!");

    this.name = "UnknownContentTypeHeaderError";
    this.message = contentTypeHeader || "Missing content type header";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnknownContentTypeHeaderError);
    }
  }
}

export default UnknownContentTypeHeaderError;
