import GET__users_rewards from 'network-layer/endpoints/GET__users_rewards'
import { GetUserRewardsParams } from 'services/types'

const getRewards = async (params: GetUserRewardsParams) => {
  const { authToken } = params

  if (!authToken) {
    return []
  }

  return await GET__users_rewards(params)
}

export default getRewards
