import CheckIcon from '@mui/icons-material/Check'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import EuroIcon from '@mui/icons-material/Euro'
import { Box, Button, Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'
import { DatePickerComponent } from 'components/DatePickerComponent'
import PageContent from 'components/PageContent'
import { PageContentColumnWrapper } from 'components/PageContentColumnWrapper'
import { PageContentLeftColumn } from 'components/PageContentLeftColumn'
import { PageContentRightColumn } from 'components/PageContentRightColumn'
import { PageHeader } from 'components/PageHeader'
import { PageHeaderTitle } from 'components/PageHeaderTitle'
import { SelectComponent } from 'components/SelectComponent'
import TextFieldComponent from 'components/TextFieldComponent'
import AppContext from 'contexts/App'
import dayjs, { Dayjs } from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import getPlayerRewards from 'services/Admin/getPlayerRewards'
import savePlayer from 'services/Admin/savePlayer'
import savePlayerRewards from 'services/Admin/savePlayerRewards'
import getPlayers from 'services/Players/getPlayers'
import { IntervalType, PlayerRewardType } from 'services/types'
import type { Player } from 'types'
import styles from './AdminPanel.module.scss'

const intervals = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const AdminPanel = () => {
  const [section, setSection] = useState<'rewards'>('rewards')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>()
  const [players, setPlayers] = useState<Array<Player>>([])
  const [isInitialized, setIsInitialized] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [selectedInterval, setSelectedInterval] = useState<IntervalType>('')
  const [amount, setAmount] = useState('')
  const [selectedPlayerRewards, setSelectedPlayerRewards] = useState<Array<PlayerRewardType>>([])
  const [formnattedPlayerRewards, setFormattedPlayerRewards] =
    useState<Array<PlayerRewardType | null>>()
  const [notes, setNotes] = useState('')

  const { authToken, user } = useContext(AppContext)

  useEffect(() => {
    if (!formnattedPlayerRewards) {
      setFormattedPlayerRewards(intervals.map(() => null))
    }
  }, [formnattedPlayerRewards])

  useEffect(() => {
    setAmount('')
    setNotes('')
  }, [selectedInterval])

  useEffect(() => {
    const initializeRewards = async () => {
      try {
        const resPlayers = await getPlayers({ authToken: authToken, isAdmin: user?.isAdmin })
        setPlayers(resPlayers)
        setIsInitialized(true)
      } catch (error) {
        // console.log(error)
        setIsInitialized(true)
      }
    }

    if (!isInitialized) {
      initializeRewards()
    }
  }, [authToken, isInitialized, user])

  useEffect(() => {
    if (selectedPlayerId) {
      const selectedPlayer = players.filter(
        (player: Player) => player.playerId === parseInt(selectedPlayerId, 10),
      )[0]
      setStartDate(selectedPlayer.contractStartDate || '')
      setFormattedPlayerRewards(intervals.map(() => null))
    }
  }, [players, selectedPlayerId])

  const savePlayerStartDate = async () => {
    try {
      setIsLoading(true)

      await savePlayer({
        authToken: authToken || '',
        playerId: parseInt(selectedPlayerId as string, 10),
        contractStartDate: startDate,
      })

      setIsLoading(false)
    } catch (error) {
      // console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const playerRewards = async () => {
      try {
        setIsLoading(true)

        const resPlayerRewards = await getPlayerRewards({
          authToken: authToken || '',
          playerId: parseInt(selectedPlayerId as string, 10),
        })

        setSelectedPlayerRewards(resPlayerRewards)

        setIsLoading(false)
      } catch (error) {
        // console.log(error)
        setIsLoading(false)
      }
    }

    if (selectedPlayerId) {
      playerRewards()
    }
  }, [authToken, selectedPlayerId])

  useEffect(() => {
    if (selectedPlayerRewards.length) {
      const formattedRewards = intervals.map((intervalId) => {
        const rewardObj = selectedPlayerRewards.filter(
          (rewardObj) => parseInt(rewardObj.period, 10) === intervalId,
        )[0]

        return rewardObj || null
      })

      setFormattedPlayerRewards(formattedRewards)
    }
  }, [selectedPlayerRewards])

  const saveReward = async () => {
    try {
      setIsLoading(true)

      await savePlayerRewards({
        authToken: authToken || '',
        playerId: parseInt(selectedPlayerId || '', 10),
        amount: parseInt(amount, 10),
        period: selectedInterval,
        notes: notes || '',
      })

      const resPlayerRewards = await getPlayerRewards({
        authToken: authToken || '',
        playerId: parseInt(selectedPlayerId as string, 10),
      })

      setSelectedPlayerRewards(resPlayerRewards)

      setIsLoading(false)
    } catch (error) {
      // console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <PageContent>
      <PageContentColumnWrapper>
        {/* left column */}
        <PageContentLeftColumn>
          <MenuItem
            className={styles.menuItem}
            onClick={() => {
              setSection('rewards')
            }}
            disabled={false}
          >
            <ListItemIcon>
              <CurrencyExchangeIcon fontSize="small" className={styles.menuItem_icon} />
            </ListItemIcon>
            <ListItemText>Rewards</ListItemText>
          </MenuItem>
        </PageContentLeftColumn>

        {/* right column */}
        <PageContentRightColumn>
          <PageHeader>
            <PageHeaderTitle>Admin Panel</PageHeaderTitle>
          </PageHeader>

          {/* Player Rewards */}
          {section === 'rewards' && (
            <>
              <div className={cn(styles.row)}>
                <div className={styles.column}>
                  <Typography variant="h5" marginBottom={2}>
                    Rewards
                  </Typography>
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column}>
                  <Typography variant="body1" marginBottom={2} marginTop={2}>
                    Select Player
                  </Typography>
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column}>
                  <SelectComponent
                    required={true}
                    label="Player"
                    value={selectedPlayerId || ''}
                    onChange={({ target }) => {
                      setSelectedPlayerId(target.value)
                    }}
                    helperText="Select a player"
                    disabled={isLoading}
                  >
                    {players.map((player) => (
                      <MenuItem key={player.playerId} value={player.playerId}>
                        {player.firstName} {player.lastName}
                      </MenuItem>
                    ))}
                  </SelectComponent>
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column}>
                  <Typography variant="body1" marginBottom={2} marginTop={2}>
                    Set Player Start Date
                  </Typography>
                </div>
              </div>
              <div className={cn(styles.row, styles.row_auto)}>
                <div className={styles.column_left}>
                  <DatePickerComponent
                    disabled={isLoading || !selectedPlayerId}
                    value={dayjs(startDate)}
                    onChange={(newValue: Dayjs | null) => {
                      if (newValue) {
                        setStartDate(
                          `${newValue.get('year')}-${newValue.get('month') + 1}-${newValue.get(
                            'date',
                          )}`,
                        )
                      } else {
                        setStartDate('')
                      }
                    }}
                    label="Contract start date"
                    required={startDate === ''}
                    helperText={startDate === '' ? 'This is a required field' : ''}
                  />
                </div>
                <div className={styles.column_right}>
                  <Box paddingTop="24px" width="100%">
                    <Button
                      disabled={!startDate || isLoading}
                      fullWidth
                      variant="contained"
                      startIcon={<CheckIcon />}
                      onClick={savePlayerStartDate}
                      sx={{
                        borderRadius: '10px',
                        height: '40px',
                        '&:disabled': {
                          backgroundColor: '#777777',
                          color: '#9f9f9f',
                        },
                      }}
                    >
                      Save start date
                    </Button>
                  </Box>
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column}>
                  <Typography variant="body1" marginBottom={2} marginTop={2}>
                    Set Player Rewards
                  </Typography>
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column_left}>
                  <SelectComponent
                    disabled={isLoading || !selectedPlayerId}
                    required={true}
                    label="Interval"
                    value={selectedInterval}
                    onChange={({ target }) => {
                      setSelectedInterval(target.value as IntervalType)
                    }}
                    helperText="Select an interval"
                  >
                    {intervals.map((intervalId) => (
                      <MenuItem key={intervalId} value={intervalId}>
                        {intervalId}
                      </MenuItem>
                    ))}
                  </SelectComponent>
                </div>
                <div className={styles.column_right}>
                  <TextFieldComponent
                    disabled={isLoading || !selectedPlayerId}
                    required={true}
                    label="Amount"
                    value={amount}
                    onChange={({ target }) => {
                      setAmount(target.value)
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EuroIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="number"
                  />
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column}>
                  <Typography variant="body1" marginBottom={2} marginTop={2}>
                    Set Reward Notes
                  </Typography>
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={cn(styles.column, styles.column_fullWidth)}>
                  <TextFieldComponent
                    disabled={isLoading || !selectedPlayerId}
                    required={true}
                    label="Notes"
                    value={notes}
                    onChange={({ target }) => {
                      setNotes(target.value)
                    }}
                    type="text"
                  />
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column_left} />
                <div className={styles.column_right}>
                  <Box paddingTop="24px" width="100%">
                    <Button
                      disabled={isLoading || !selectedInterval || !amount || !selectedPlayerId}
                      fullWidth
                      variant="contained"
                      startIcon={<CheckIcon />}
                      onClick={saveReward}
                      sx={{
                        borderRadius: '10px',
                        height: '40px',
                        '&:disabled': {
                          backgroundColor: '#777777',
                          color: '#9f9f9f',
                        },
                      }}
                    >
                      Save Interval Information
                    </Button>
                  </Box>
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column}>
                  <Typography variant="body1" marginBottom={2} marginTop={2}>
                    Current Rewards
                  </Typography>
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div className={styles.column}>
                  <Table sx={{}} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.cell}>Interval</TableCell>
                        <TableCell className={styles.cell}>Ammount</TableCell>
                        <TableCell className={styles.cell}>Notes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(formnattedPlayerRewards || []).map((rewardObj, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell className={rewardObj ? styles.completedcell : styles.cell}>
                            {rewardObj ? rewardObj.period : index + 1}
                          </TableCell>
                          <TableCell className={rewardObj ? styles.completedcell : styles.cell}>
                            {rewardObj ? rewardObj.amount : 'N/A'}
                          </TableCell>
                          <TableCell className={rewardObj ? styles.completedcell : styles.cell}>
                            {rewardObj && rewardObj.notes !== '' ? rewardObj.notes : 'N/A'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </>
          )}
        </PageContentRightColumn>
      </PageContentColumnWrapper>
    </PageContent>
  )
}

export default AdminPanel
