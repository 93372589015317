import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import { GoogleCredentialResponse, GoogleLogin } from '@react-oauth/google'
import LoadingOverlay from 'components/LoadingOverlay'
import TextFieldComponent from 'components/TextFieldComponent'
import appleLogo from 'images/appleLogo.svg'
import React, { useState } from 'react'
import AppleSignin from 'react-apple-signin-auth'
import login from 'services/Auth/login'
import getSecret from 'services/Payment/getSecret'
import getPlayers from 'services/Players/getPlayers'
import getRewards from 'services/Users/getRewards'
import getUser from 'services/Users/getUser'
import { Reward } from 'types'
import isEmail from 'util/validators/isEmail'
import isNotEmpty from 'util/validators/isNotEmpty'
import { LoginSectionProps } from './types'

const LoginSection = ({
  setAuthToken,
  setUser,
  setPlayers,
  setStripeSecret,
  setIsAuthenticated,
  setSection,
  setRewards,
}: LoginSectionProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [hasRequestError, setHasRequestError] = useState<boolean>(false)
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] = useState<string>('')
  const [emailValidationErrorMessage, setEmailValidationErrorMessage] = useState<string>('')

  const handleChangeEmail = (event: React.FormEvent<EventTarget>) => {
    let target = event.target as HTMLInputElement
    setHasRequestError(false)
    setEmail(target.value.toLowerCase().trim())
    setEmailValidationErrorMessage(isEmail(target.value))
  }

  const handleChangePassword = (event: React.FormEvent<EventTarget>) => {
    let target = event.target as HTMLInputElement
    setHasRequestError(false)
    setPassword(target.value)
    setPasswordValidationErrorMessage(isNotEmpty(target.value))
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleLogin = async (type: string, credentialsResponse?: GoogleCredentialResponse) => {
    try {
      setHasRequestError(false)
      setIsLoading(true)

      const refHeader = localStorage.getItem('refHeader')

      let resLogin

      if (type === 'email') {
        resLogin = await login({
          email,
          password,
          refHeader: refHeader || '',
        })
      } else if (type === 'google') {
        const { credential } = credentialsResponse as GoogleCredentialResponse
        resLogin = await login({
          credential,
          auth_provider: 'google',
          refHeader: refHeader || '',
        })
      } else if (type === 'apple') {
        resLogin = await login({
          credential: credentialsResponse as string,
          auth_provider: 'apple',
          refHeader: refHeader || '',
        })
      }

      const { token } = resLogin
      localStorage.setItem('authToken', token)

      const resUser = await getUser({ authToken: token })
      setUser(resUser)

      const resSecret = await getSecret({ authToken: token })
      setStripeSecret(resSecret.key)

      const resPlayers = await getPlayers({ authToken: token })
      setPlayers(resPlayers)

      const resRewards = await getRewards({ authToken: token })
      const filteredRewards = resRewards.filter((el: Reward) => el.periods.length > 0)
      setRewards(filteredRewards)

      setAuthToken(token)
      setIsAuthenticated(true)
      localStorage.removeItem('refHeader')
      setIsLoading(false)
      setSection('success_login')
    } catch (error) {
      // TODO: error handling
      // console.log(error)
      setIsLoading(false)
      setHasRequestError(true)
    }
  }

  return (
    <Box
      style={{
        width: '100%',
        borderRadius: 10,
      }}
    >
      {isLoading && <LoadingOverlay />}

      {hasRequestError && (
        <Typography style={{ marginBottom: 8 }} fontWeight={700}>
          Wrong username or password. Please check your credentials and try again.
        </Typography>
      )}
      <Box
        style={{
          height: '100px',
        }}
      >
        <TextFieldComponent
          required
          value={email}
          label="Username"
          onChange={handleChangeEmail}
          disabled={isLoading}
          fieldVariant="login"
          onBlur={() => {
            if (email === '') {
              setEmailValidationErrorMessage('')
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              handleLogin('email')
            }
          }}
          helperText={emailValidationErrorMessage}
          error={!!emailValidationErrorMessage}
        />
      </Box>
      <Box
        style={{
          height: '100px',
        }}
      >
        <TextFieldComponent
          required
          value={password}
          label="Password"
          onChange={handleChangePassword}
          disabled={isLoading}
          fieldVariant="login"
          type={showPassword ? 'text' : 'password'}
          onBlur={() => {
            if (password === '') {
              setPasswordValidationErrorMessage('')
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              handleLogin('email')
            }
          }}
          helperText={passwordValidationErrorMessage}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!passwordValidationErrorMessage}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          marginTop: '16px',
          marginBottom: '16px',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          disabled={Boolean(
            isLoading ||
              email === '' ||
              emailValidationErrorMessage ||
              password === '' ||
              passwordValidationErrorMessage,
          )}
          size="large"
          variant="contained"
          style={{ width: '50%' }}
          onClick={() => {
            handleLogin('email')
          }}
        >
          Login
        </Button>
      </Box>
      <Divider>OR</Divider>
      <Box
        style={{
          display: 'flex',
          marginTop: '16px',
          marginBottom: '16px',
          width: '500px',
          justifyContent: 'space-between',
        }}
      >
        <GoogleLogin
          text="continue_with"
          width={240}
          onSuccess={(credentialsResponse) => {
            handleLogin('google', credentialsResponse)
          }}
          onError={() => {
            // console.log('Login Failed')
          }}
        />
        <AppleSignin
          /** Auth options passed to AppleID.auth.init() */
          authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: 'com.nordensa.web',
            /** Requested scopes, seperated by spaces - eg: 'email name' */
            scope: 'email name',
            /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs
             *  is that you should call auth from a page that is listed as a redirectURI, localhost fails */
            redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL || '',
            /** State string that is returned with the apple response */
            state: 'state',
            /** Nonce */
            nonce: 'nonce',
            /** Uses popup auth instead of redirection */
            usePopup: true,
          }} // REQUIRED
          /** General props */
          uiType="light"
          /** className */
          className="apple-auth-btn"
          /** Removes default style tag */
          noDefaultStyle={false}
          /** Allows to change the button's children, eg: for changing the button text */
          // buttonExtraChildren="Continue with Apple"
          /** Extra controlling props */
          /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
          onSuccess={(response: any) => {
            const {
              authorization: { id_token },
            } = response

            handleLogin('apple', id_token)
          }} // default = undefined
          /** Called upon signin error */
          onError={(error: any) => {
            console.error(error)
            setIsLoading(false)
            setHasRequestError(true)
          }} // default = undefined
          /** Skips loading the apple script if true */
          skipScript={false} // default = undefined
          /** Apple image props */
          // iconProps={{ style: { marginTop: '10px' } }} // default = undefined
          /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
          render={(props: any) => (
            <Button
              disableRipple
              size="large"
              variant="contained"
              style={{
                width: '240px',
                backgroundColor: '#fff',
                color: '#000',
                textTransform: 'none',
                height: '39px',
                boxShadow: 'none',
                borderRadius: '4px',
                fontSize: '14px',
                textAlign: 'center',
              }}
              {...props}
            >
              <img src={appleLogo} alt="Apple logo" style={{ width: 50 }} />
              <Typography variant="body2" mt="2px">
                Continue with Apple
              </Typography>
            </Button>
          )}
        />
      </Box>
      <Divider />
    </Box>
  )
}

export default LoginSection
