import AppContext from 'contexts/App'
import { ErrorPage } from 'pages/ErrorPage'
import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({
  children,
  adminRequired = false,
}: {
  children: React.ReactElement
  adminRequired?: boolean
}) => {
  const { appIsInitialized, isAuthenticated, user } = useContext(AppContext)

  if (appIsInitialized && !isAuthenticated) {
    return <Navigate to="/" replace />
  }

  if (adminRequired && (!user || !user.isAdmin)) {
    return (
      <ErrorPage
        code="403"
        message="You do not have the necessary permissions to access this page."
      />
    )
  }

  return children
}

export default ProtectedRoute
