import Typography from '@mui/material/Typography'
import styles from './PageHeaderTitle.module.scss'
import { PageHeaderTitleProps } from './types'

const PageHeaderTitle = ({ children }: PageHeaderTitleProps) => {
  return (
    <span className={styles.pageHeaderTitle}>
      <Typography variant="h4">{children}</Typography>
    </span>
  )
}

export default PageHeaderTitle
