const containsLowercase = (value: string) => {
  const validationRule = new RegExp(/[a-z]/)

  if (!validationRule.test(value)) {
    return 'Must contain at least one lowercase letter'
  }

  return ''
}

export default containsLowercase
