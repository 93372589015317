import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import LoadingOverlay from 'components/LoadingOverlay'
import TextFieldComponent from 'components/TextFieldComponent'
import React, { useState } from 'react'
import forgotPassword from 'services/Auth/forgotPassword'
import login from 'services/Auth/login'
import resetPassword from 'services/Auth/resetPassword'
import getSecret from 'services/Payment/getSecret'
import getPlayers from 'services/Players/getPlayers'
import getUser from 'services/Users/getUser'
import isEmail from 'util/validators/isEmail'
import isForgotPasswordToken from 'util/validators/isForgotPasswordToken'
import isPassword from 'util/validators/isPassword'
import { ForgotPasswordSectionProps } from './types'

const ForgotPasswordSection = ({
  setAuthToken,
  setUser,
  setPlayers,
  setStripeSecret,
  setIsAuthenticated,
  setSection,
}: ForgotPasswordSectionProps) => {
  const [step, setStep] = useState<'submit' | 'reset'>('submit') // 'submit' | 'reset'
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [resetEmail, setResetEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [token, setToken] = useState('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [hasRequestError, setHasRequestError] = useState<boolean>(false)
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] = useState<string>('')
  const [emailValidationErrorMessage, setEmailValidationErrorMessage] = useState<string>('')
  const [tokenValidationErrorMessage, setTokenValidationErrorMessage] = useState<string>('')
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleChangeEmail = (event: React.FormEvent<EventTarget>) => {
    let target = event.target as HTMLInputElement
    setHasRequestError(false)
    setEmail(target.value)
    setEmailValidationErrorMessage(isEmail(target.value))
  }

  const handleChangeToken = (event: React.FormEvent<EventTarget>) => {
    let target = event.target as HTMLInputElement
    setHasRequestError(false)
    setToken(target.value)
    setTokenValidationErrorMessage(isForgotPasswordToken(target.value))
  }

  const handleChangePassword = (event: React.FormEvent<EventTarget>) => {
    let target = event.target as HTMLInputElement
    setHasRequestError(false)
    setPassword(target.value)
    setPasswordValidationErrorMessage(isPassword(target.value))
  }

  const handleSubmit = async () => {
    setHasRequestError(false)
    setIsLoading(true)
    try {
      await forgotPassword({ email })
      setResetEmail(email)
      setStep('reset')
      setIsLoading(false)
    } catch (error) {
      // TODO: error handling
      // console.log(error)
      setEmail('')
      setHasRequestError(true)
      setIsLoading(false)
    }
  }

  const handleReset = async () => {
    try {
      setHasRequestError(false)
      setIsLoading(true)
      await resetPassword({
        email: resetEmail,
        token,
        password,
      })

      const refHeader = localStorage.getItem('refHeader')

      const resLogin = await login({
        email,
        password,
        refHeader: refHeader || '',
      })
      localStorage.setItem('authToken', resLogin.token)

      const resUser = await getUser({ authToken: resLogin.token })
      setUser(resUser)

      const resSecret = await getSecret({ authToken: resLogin.token })
      setStripeSecret(resSecret.key)

      const resPlayers = await getPlayers({ authToken: resLogin.token })
      setPlayers(resPlayers)

      setAuthToken(resLogin.token)
      setIsAuthenticated(true)
      localStorage.removeItem('refHeader')
      setSection('success_forgot_password')
      setIsLoading(false)
    } catch (error) {
      // TODO: error handling
      // console.log(error)
      setHasRequestError(true)
      setIsLoading(false)
    }
  }

  return (
    <Box
      style={{
        width: '100%',
        borderRadius: 10,
      }}
    >
      {isLoading && <LoadingOverlay />}

      {hasRequestError && (
        <Typography style={{ marginBottom: 8 }} fontWeight={700}>
          We encountered an unexpected error. Please try again later.
        </Typography>
      )}

      {/* Submit section */}
      <Typography style={{ marginBottom: 8 }}>
        Please enter your email address in the field below. If the e-mail address is associated with
        an account, you will receive an e-mail with a reset password token.
      </Typography>
      <Box
        style={{
          height: '100px',
        }}
      >
        <TextFieldComponent
          required
          value={email}
          label="E-mail address"
          onChange={handleChangeEmail}
          disabled={isLoading || step === 'reset'}
          fieldVariant="login"
          onBlur={() => {
            if (email === '') {
              setEmailValidationErrorMessage('')
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              handleSubmit()
            }
          }}
          helperText={emailValidationErrorMessage}
          error={!!emailValidationErrorMessage}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          disabled={Boolean(
            isLoading || email === '' || emailValidationErrorMessage || step === 'reset',
          )}
          size="large"
          variant="contained"
          style={{ width: '50%' }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>

      {/* Reset section */}
      {step === 'reset' && (
        <Typography style={{ marginTop: 8, marginBottom: 8 }}>
          Please enter the reset password token you received by e-mail together with your new
          password in the fields below.
        </Typography>
      )}

      <Box
        style={{
          height: '100px',
        }}
      >
        <TextFieldComponent
          disabled={isLoading || step !== 'reset'}
          error={!!tokenValidationErrorMessage}
          label="Reset Password Token"
          value={token}
          fieldVariant="login"
          onChange={handleChangeToken}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              handleReset()
            }
          }}
          onBlur={() => {
            if (token === '') {
              setTokenValidationErrorMessage('')
            }
          }}
          helperText={tokenValidationErrorMessage}
        />
      </Box>
      <Box
        style={{
          height: '100px',
        }}
      >
        <TextFieldComponent
          required
          value={password}
          label="New Password"
          onChange={handleChangePassword}
          disabled={isLoading || step !== 'reset'}
          fieldVariant="login"
          type={showPassword ? 'text' : 'password'}
          onBlur={() => {
            if (password === '') {
              setPasswordValidationErrorMessage('')
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              handleReset()
            }
          }}
          helperText={passwordValidationErrorMessage}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={isLoading || step !== 'reset'}
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword()}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!passwordValidationErrorMessage}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          marginTop: '16px',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={handleReset}
          size="large"
          style={{ width: '50%' }}
          variant="contained"
          fullWidth
          disabled={Boolean(
            step !== 'reset' ||
              isLoading ||
              !token ||
              tokenValidationErrorMessage ||
              !password ||
              passwordValidationErrorMessage,
          )}
        >
          Reset password
        </Button>
      </Box>
    </Box>
  )
}

export default ForgotPasswordSection
