import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import { CloseButtonProps } from './types'

const CloseButton = ({ handleOnClose }: CloseButtonProps) => {
  return (
    <Button
      variant="contained"
      sx={{
        padding: '0px',
        height: '35px',
        width: '35px',
        minWidth: '35px',
        borderRadius: '10px',
        backgroundColor: '#3C484C',
        color: '#F2F2F3',
      }}
      size="small"
      onClick={handleOnClose}
    >
      <CloseIcon />
    </Button>
  )
}

export default CloseButton
