import { PageContentProps } from 'types'
import './index.css'

const PageContent = ({ children }: PageContentProps) => {
  return (
    <div className="pageContentWrapper">
      <div className="pageContent">
        <div className="pageWrapper">{children}</div>
      </div>
    </div>
  )
}

export default PageContent
