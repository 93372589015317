import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorPageProps } from './types'

const ErrorPage = ({
  code = '404',
  message = 'We could not locate the page you are looking for.',
}: ErrorPageProps) => {
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    const x = setInterval(() => {
      setCountdown(countdown - 1)

      if (countdown === 1) {
        clearInterval(x)
        navigate('/')
      }
    }, 1000)

    return () => {
      clearInterval(x)
    }
  }, [navigate, countdown])

  return (
    <Box
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
      }}
    >
      <Typography variant="h3">{code}</Typography>
      <Typography variant="body1">{message}</Typography>
      <Typography variant="body1">
        You will be redirected to the main page in {countdown} seconds.
      </Typography>
    </Box>
  )
}

export default ErrorPage
