import successBody from '../util/successBody'

const GET = async (endpoint: string, config: Record<string, unknown>) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    ...config,
    method: 'GET',
  })

  return await successBody(response)
}

export default GET
