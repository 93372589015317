import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import RequiredSign from 'components/Required'
import { TextFieldComponentProps } from './types'

const TextFieldComponent = ({
  value,
  onChange,
  onKeyDown = () => {},
  onBlur = () => {},
  onFocus = () => {},
  helperText,
  label,
  icon,
  disabled,
  required,
  fieldVariant = 'default',
  error = false,
  inputProps = {},
  type = 'text',
}: TextFieldComponentProps) => {
  const labelColor = fieldVariant === 'default' ? '#fafcf2' : '#FAFCF2'

  const variants = {
    default: {
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: '#3a5459',
      },
      '& .MuiInputBase-root': {
        borderRadius: '10px',
        border: 'none',
        backgroundColor: '#122529',
        color: '#fafcf2',
        '&:hover': {
          boxShadow: '0px 0px 0px 4px rgba(252, 109, 75, 0.25)',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
        },
      },
      '& .MuiOutlinedInput-root': {
        fieldset: {
          border: '1px solid #65665E',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FC6D4B',
          borderRadius: '10px',
          boxShadow: '0px 0px 0px 4px rgba(252, 109, 75, 0.25)',
        },
      },
      '& .MuiFormHelperText-root': {
        marginLeft: '0px',
        color: '#a2a39b',
      },
      '& .MuiTypography-caption': {
        color: '#fafcf2',
      },
    },
    dialog: {
      '& .MuiInputBase-root': {
        borderRadius: '10px',
        border: '1px solid #fff',
        backgroundColor: '#FC6D4B',
        boxShadow: 'none',
        color: '#FAFCF2',
        '&:hover': {
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
        },
      },
      '& .MuiOutlinedInput-root': {
        fieldset: {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          borderRadius: '10px',
          border: 'none',
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
      },
      '& .MuiFormHelperText-root': {
        marginLeft: '0px',
        color: '#FAFCF2',
      },
    },
    login: {
      '& .MuiInputBase-root': {
        borderRadius: '10px',
        border: `2px solid ${error ? '#9f5445' : '#E4E5DB'}`,
        backgroundColor: '#E4E5DB',
        boxShadow: 'none',
        // color: '#24251E',
        color: '#000000',
        '&:hover': {
          border: '2px solid #65665E',
          boxShadow: '0px 0px 0px 4px rgba(60, 72, 76, 0.40)',
        },
        '&:focus-within': {
          border: '2px solid #65665E',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
          border: 'none',
          backgroundColor: '#C1C2B9',
        },
      },
      '& .MuiOutlinedInput-root': {
        fieldset: {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          borderRadius: '10px',
          border: 'none',
          boxShadow: 'none',
        },
      },
      '& .MuiFormHelperText-root': {
        marginLeft: '0px',
        color: '#000000',
        '&.Mui-error': {
          marginLeft: '0px',
          color: '#000000',
        },
      },
    },
  }

  const labelSize: Record<'default' | 'dialog' | 'login', 'caption' | 'body1'> = {
    login: 'body1',
    default: 'caption',
    dialog: 'caption',
  }

  return (
    <FormControl fullWidth>
      <Typography
        variant={labelSize[fieldVariant] || 'caption'}
        style={{ marginBottom: '4px', color: `${labelColor}` }}
      >
        {label} {required && <RequiredSign variant={fieldVariant} />}
      </Typography>
      <TextField
        type={type}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        required={required}
        disabled={disabled}
        variant="outlined"
        size="small"
        fullWidth
        helperText={helperText}
        value={value}
        onChange={onChange}
        sx={variants[fieldVariant]}
        InputProps={inputProps}
        InputLabelProps={{
          style: { color: '#fff' },
        }}
        error={error}
      />
    </FormControl>
  )
}

export default TextFieldComponent
