import PageContent from 'components/PageContent'
import { PageHeaderTitle } from 'components/PageHeaderTitle'

const PrivacyPolicy = () => {
  return (
    <PageContent>
      <PageHeaderTitle>Privacy Policy</PageHeaderTitle>
      <div>
        <p>
          <strong>Privacy notice regarding the processing of personal data</strong>
        </p>
        <p>
          <span>
            <strong>Last updated: June 12, 2023</strong>
          </span>
        </p>
        <p>
          <strong>Nordensa Football SRL</strong>, registered with the Trade Registry Office next to
          the Cluj Court under no. J12/6438/2022, fiscal code 47032637, Paris St. no. 102,
          Cluj-Napoca &nbsp;(referred to as either “Nordensa”, “Controller”, “We”, “Us” or “Our” in
          this Privacy notice regarding the processing of personal data) acts as controller for
          processing your personal data. The processing of personal data shall be done in accordance
          with the Regulation (EU) 2016/679 on the protection of natural persons regarding the
          processing of personal data and on the free movement of such data, and repealing
          Directive&nbsp;95/46/EC (“<strong>GDPR</strong>”), as well as any other legal provisions
          applicable with respect to the protection of personal data.&nbsp;
        </p>
        <p>
          Nordensa is the controller of personal data it collects from and about users through the
          Nordensa Platform and/or by any other interactions we may have with you, like those
          relating to the provision of Our services or during marketing activities.
        </p>
        <p>
          This Privacy notice regarding the processing of personal data (
          <strong>“Privacy notice“</strong>) describes in detail how We process the personal data of
          the Nordensa Platform users and the rights they have in connection with this processing.
        </p>
        <p>
          This Privacy notice applies to all users, regardless whether they are an unregistered
          user, Registered User or Backer, individuals and representatives of legal entities alike (
          <strong>“Users“</strong>). Additionally, to the extent that the User represents a legal
          entity that will act through the Nordensa Platform, Nordensa will process the personal
          data of the individuals acting on behalf of those.&nbsp;{' '}
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Interpretation and Definitions</strong>
        </p>
        <p>
          All terms and concepts included herein in capital letters and not defined elsewhere shall
          have the same meaning as provided in the{' '}
          <a href="https://www.nordensa.com/terms-and-conditions">Nordensa Terms and Conditions</a>.
        </p>
        <p>
          Defined terms shall have the same meaning regardless of whether they appear in singular or
          in plural.
          <br />
          <br />
        </p>
        <p>
          <strong>Processing of personal data</strong>
        </p>
        <ol data-rte-list="default">
          <li>
            <p>
              <strong>Types of Data Collected</strong>
            </p>
          </li>
        </ol>
        <p>
          While using Our Services, we will collect the following types of personal data:
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Identification data</strong>: name, surname,
          country of residence, data included in your identification documents (passport/national ID
          number, citizenship)
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Contact information</strong>: e-mail address
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Bank information</strong>: IBAN number,
          credit/debit card details
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Signature and photography</strong>
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
          <strong>
            Any information that may represent personal data included in the interactions with the
            Nordensa Platform
          </strong>
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Usage Data: </strong>
        </p>
        <p>
          &nbsp;&nbsp; <em>When accessing the web version of the Nordensa Platform</em>: the
          device’s Internet Protocol address (e.g. IP address), browser type, browser version,
          accessed pages of the Nordensa Platform, the time and date of the visit on the Nordensa
          Platform, the time spent on those pages, unique device identifiers and other diagnostic
          data.
        </p>
        <p>
          &nbsp;&nbsp; <em>When accessing the Nordensa Platform via a mobile device</em>: the type
          of mobile device, mobile device unique ID, the IP address of the mobile device, mobile
          operating system, the type of mobile Internet browser used, unique device identifiers and
          other diagnostic data.
          <br />
          <br />
          &nbsp; &nbsp;<em>Data regarding Users’ interaction with the Nordensa Platform</em>: likes,
          comments, information contained in the user profile.
        </p>
        <p></p>
        <p>
          We will collect personal data either directly from the Users (in the case of
          identification data, contact information or bank information) or automatically through
          technical means (in the case of usage data).{' '}
        </p>
        <p></p>
        <p>
          {' '}
          2. <strong>Purposes and legal basis for the processing of personal data</strong>
        </p>
        <p>
          <strong>Name and e-mail address</strong>
        </p>
        <p>
          Purpose: Managing your registration as a Registered User and to provide access to the
          functionalities of the Service (account creation)
        </p>
        <p>
          Legal Basis: Performance of a contract concluded with you regarding the provision of our
          Services (as per the <a href="/terms-and-conditions">Terms and Conditions</a>), pursuant
          to Article 6 para. (1) lit. b) of GDPR.
        </p>
        <p>
          <br />
          <strong>
            Data included in the User profile, interactions with the Nordensa Platform and
            information about those
          </strong>
        </p>
        <p>
          Purpose: Providing the functionalities of the Nordensa Platform to Registered Users
          <br />
          <br />
          Legal Basis: Performance of a contract concluded with you regarding the provision of our
          Services (as per the Terms and Conditions), pursuant to Article 6 para. (1) lit. b) of
          GDPR.
          <strong>
            <br />
            <br />
            <br />
            Name, surname, country of residence, bank information
          </strong>
        </p>
        <p>Purposes: </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Providing Services through concluding a JV Agreement with
          the Users{' '}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Identifying payments made by Users for validation purposes
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Administration of our Services</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delivering the Nordensa Platform content, campaigns and
          interaction with Users
        </p>
        <p>
          Legal basis: Performance of a contract concluded with you regarding the provision of our
          Services (as per the Terms and Conditions), pursuant to Article 6 para. (1) lit. b) of
          GDPR
        </p>
        <p>
          <strong>
            <br />
            Data included in identification documents including the photography
          </strong>
          &nbsp;
        </p>
        <p>Purpose: </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <em>
            Verifying the identify of Users prior to concluding a JV Agreement with the Users&nbsp;
          </em>{' '}
        </p>
        <p>Legal basis: </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Legitimate interest of Nordensa to ensure security of its
          business relationships, pursuant to article to Article 6 para. (1) lit. f) of GDPR
          <br />
        </p>
        <p>
          <strong>Contact information </strong>
        </p>
        <p>Purposes: </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Managing the relationship with Users, including managing
          User requests and complaints and responding to those.
        </p>
        <p>
          &nbsp; &nbsp;Contacting Users by email or other equivalent forms of electronic
          communication, such as a mobile application’s push notifications regarding updates or
          informative communications related to the functionalities, products, or contracted
          services, including the security updates, when necessary or reasonable for their
          implementation.
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Providing users&nbsp;with news, special offers, and general
          information about other goods, services, and events which we offer that are similar to
          those that already purchased or enquired about, only when Users opted in to receive such
          information
        </p>
        <p>Legal basis: </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Conclusion and performance of a contract regarding the
          provision of our Services pursuant to Article 6 para. (1) lit. b) of GDPR
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Legitimate interest of Nordensa to improve or modify the
          services offered and to manage its business pursuant to Article 6 para. (1) lit. f) of
          GDPR
        </p>
        <p>
          <strong>Usage Data</strong>
        </p>
        <p>Purposes:</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delivering the Nordensa Platform content, campaigns, and
          interaction with Users&nbsp;
        </p>
        <p> </p>
        <p>Legal basis:</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Performance of a contract concluded with you regarding the
          provision of our Services (as per the{' '}
          <a href="/terms-and-conditions">Terms and Conditions</a>), pursuant to Article 6 para. (1)
          lit. b) of GDPR
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            Sharing personal data <br />
          </strong>
          &nbsp;
        </p>
        <p>
          <strong>1.&nbsp;&nbsp;&nbsp;&nbsp; Recipients of personal data</strong>
        </p>
        <p>
          We will share your personal data with a limited number of third parties, in the following
          situations:
        </p>
        <p>
          <strong>Service Providers:</strong>&nbsp;payment processors, marketing providers, IT
          service providers, e-signature providers, e-mailing service providers, other providers of
          professional services (accountants, lawyers, consultants, auditors, professional advisors,
          etc).{' '}
        </p>
        <p>
          We will share your personal data with Service Providers to monitor and analyze the use of
          our Service; show advertisements; help support and maintain our Service; to contact Users;
          to effectively manage our business and comply with regulatory requirements.
        </p>
        <p>
          &nbsp;<strong>Affiliates:</strong>&nbsp;Our joint venture partners
        </p>
        <p>
          <strong>Tax, governmental, judicial, and supervisory authorities</strong>
        </p>
        <p>
          We will share your personal data to respond to requests from authorities and comply with
          our legal obligations.{' '}
        </p>
        <p>
          Nordensa will ensure in advance that third parties enjoy confidence in ensuring the
          protection of personal data and, where applicable, that providers act on the basis of the
          express instructions of the Controller addressed through the agreements for the processing
          of personal data.
        </p>
        <p></p>
        <p>
          <strong>2.&nbsp;&nbsp;&nbsp;&nbsp; Transfers of personal data to third countries</strong>
        </p>
        <p>
          Depending on the service providers used in order to enable the functioning or the features
          of the Nordensa Platform, your personal data will be transferred to certain non-EU
          countries – more specifically, the United States of America and the United Kingdom. The
          transfer of your personal data to a third country is performed pursuant to implementing
          legally appropriate safeguards for the protection of your personal data (e.g. either based
          on an adequacy decision based on article 45 GDPR or based on standard data protection
          clauses based on article 46 GDPR). You can request details of those safeguards by
          contacting us at <a href="mailto:gdpr@nordensa.com">gdpr@nordensa.com</a>.
        </p>
        <p></p>
        <p>
          <strong>Retention periods</strong>
        </p>
        <p>
          The Company will retain personal data only for as long as it is necessary to achieve the
          purposes set out in this Privacy notice.&nbsp;
        </p>
        <p>
          Personal data processed based on the contractual relations that Users have with Nordensa
          will be stored for the entire contractual period, as well as for a subsequent period of 3
          years, calculated from the termination of the contractual relationship, unless longer
          storage periods are required by mandatory legal provisions. We will retain your data for a
          period longer than 3 years in the event of a complaint or if we have grounds to believe
          that there is a risk of a dispute in the relationship with you.
        </p>
        <p>
          The financial-accounting data will be processed for a period of 10 years, as provided by
          the financial-accounting legislation in force.
        </p>
        <p>
          After the expiry of such retention periods, we either delete or anonymize your personal
          data.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>User rights regarding the processing of their personal data </strong>
        </p>
        <p>
          The Company undertakes to respect the confidentiality of your personal data and to
          guarantee you can exercise your rights.
        </p>
        <p>According to the provisions of the GDPR, you have the following rights:</p>
        <p>
          &nbsp;<strong>The right of access to personal data</strong>: you may obtain a confirmation
          that your personal data are processed by us, information regarding such personal data, as
          well as a copy of the personal data that we hold about you.
        </p>
        <p>
          &nbsp;<strong>The right to rectify personal data: </strong>you may ask us to correct the
          incomplete or inaccurate personal data that we hold about you
        </p>
        <p>
          &nbsp;<strong>The right to restrict the processing of personal data</strong> – in
          situations expressly regulated by law (e.g. if the accuracy of personal data concerning
          the User is contested, for the period necessary to verify their accuracy, etc.) the User
          may obtain the restriction of the Controller’s processing of personal data. Restriction of
          processing means that the Controller will limit itself to storing personal data and will
          not perform other processing operations except with the user’s consent or in other cases
          expressly reviewed by law (mainly for the establishment, exercise or defense of a right in
          court or for the protection of the rights of another natural or legal person).
        </p>
        <p>
          &nbsp;<strong>The right to delete personal data (“the right to be forgotten“)</strong> –
          in situations expressly regulated by law (e.g. in case of withdrawal of consent if there
          is no other legal basis for data processing or if it is found that the personal data are
          no longer necessary for the purpose for which they were collected or processed,&nbsp;
          etc.), you can get that data deleted.
        </p>
        <p>
          &nbsp;<strong>The right to oppose the processing of personal data</strong> – the User may
          oppose at any time, for reasons related to your particular situation, the processing by
          the Controller of personal data in certain cases expressly provided for by law (in
          particular, if the processing is based on the legitimate interest of the Controller).
          Also, where the processing of personal data is for the purpose of direct marketing, the
          data subject has the right to object at any time to the processing for that purpose of
          personal data concerning him or her, including the creation of profiles.
        </p>
        <p>
          &nbsp;<strong>The right to portability of personal data</strong> – in situations expressly
          regulated by law (respectively, if the processing of personal data is carried out by the
          controller by automatic means and has as a legal basis either the performance of a
          contract or consent), the User may receive, in a structured, machine-readable format, the
          personal data that have been provided to the Operator and may request that those data be
          transmitted to another operator.
        </p>
        <p>
          &nbsp;<strong>The right to withdraw the given consent</strong> – The User may withdraw at
          any time his/her consent to the processing of personal data by the Controller. Withdrawal
          of consent will only have effect for the future, with processing carried out prior to
          withdrawal still remaining valid.
        </p>
        <p>
          Additional rights related to automated individual decision-making – Nordensa does not take
          automatic decisions as a result of the processing of personal data concerning Users.
        </p>
        <p>
          Users may exercise their above-mentioned rights at any time by submitting a written
          request (together with your contact details) and sending it in electronic format by mail
          at <a href="mailto:gdpr@nordensa.com">gdpr@nordensa.com</a>. Such exercise is free of
          charge unless your request is clearly unfounded, repetitive, or excessive, cases in which
          we may charge a reasonable fee or we may refuse to act on the request.{' '}
        </p>
        <p>
          We will resolve requests for the exercise of rights without undue delay and in any case no
          later than one month after receipt of the request. This period may be extended by two
          months where necessary. We will notify the User of the delay in solving the request,
          providing information on the deadline for response.&nbsp;
        </p>
        <p>
          Moreover, you have the right to file a complaint with the National Data Protection
          Authority (in Romania –{' '}
          <a href="http://www.dataprotection.ro">
            Autoritatea Nationala pentru Supravegherea Prelucrarii Datelor cu Caracter Personal
          </a>
          ) regarding any potential non-compliances pertaining to the processing of your personal
          data on the Nordensa Nordensa Platform.{' '}
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Use of cookies&nbsp;</strong>
        </p>
        <p>Nordensa Web Platform (https://web.nordensa.com) does not use cookies</p>
        <p>
          <strong>
            <br />
            Contact Us
          </strong>
        </p>
        <p>If you have any questions about this Privacy Notice, you can contact us:</p>
        <ul data-rte-list="default">
          <li>
            <p>By email:&nbsp;champions@nordensa.com</p>
          </li>
        </ul>
      </div>
    </PageContent>
  )
}

export default PrivacyPolicy
